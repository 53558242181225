<template>
  <div class="stat-contain">
    <div class="topData">
      <div class="school-tag">{{ schoolName }}</div>
      <div class="title">
        <div class="new-exam-tag">新高考</div>
        <div class="text">{{ topData.name }}</div>
        <div :class="['sub-tag', 'general']">
          {{ getExamType(topData.type) }}
        </div>
      </div>
      <div class="sub">
        <div class="back" @click="back"><icon type="left" /> 返回</div>
        <div class="date">
          <span>年级：{{ topData.year }}级</span>
          <span>考试时间：{{ topData.examDate }}</span>
        </div>
        <div></div>
      </div>
      <exportBtn
        :request-func="requestFunc"
        :params="{ id: $route.query.id, type: 0, subjectId: subjectId }"
        btn-type="primary"
        text="导出全部报表"
      >
        <div class="model-title">教研报告</div>
      </exportBtn>
      <div class="a-tabs">
        <a-tabs v-model="subjectId" @change="getData">
          <a-tab-pane
            v-for="item in subjectList"
            :key="item.id"
            :tab="item.name"
          >
          </a-tab-pane>
        </a-tabs>
      </div>
      <div class="dashboard">
        <div class="box">
          <div class="wrapper primary">
            <div class="row">
              <div class="data">
                <span>{{ topData.studentCount }}</span
                >名
              </div>
              <div>参考考生</div>
            </div>
            <div class="row">
              <div class="data">
                <span>{{ topData.classCount }}</span
                >个
              </div>
              <div>班级</div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-title warning">原 始 分</div>
          <div class="wrapper warning">
            <div class="row">
              <div class="data">
                <span>{{ topData.highestScoreOri }}</span>
              </div>
              <div>最高分</div>
            </div>
            <div class="row">
              <div class="data">
                <span>{{ topData.lowestScoreOri }}</span>
              </div>
              <div>最低分</div>
            </div>
          </div>
        </div>
        <div v-if="![1, 2, 3, 4, 8].includes(subjectId)" class="box">
          <div class="box-title warning">赋 分</div>
          <div class="wrapper warning">
            <div class="row">
              <div class="data">
                <span>{{ topData.highestScore }}</span>
              </div>
              <div>最高分</div>
            </div>
            <div class="row">
              <div class="data">
                <span>{{ topData.lowestScore }}</span>
              </div>
              <div>最低分</div>
            </div>
          </div>
        </div>
        <div class="box">
          <div class="box-title warning">平均分</div>
          <div class="wrapper warning">
            <div class="row">
              <div class="data">
                <span>{{ topData.averageScoreOri }}</span>
              </div>
              <div>原始分</div>
            </div>
            <div class="row">
              <div class="data">
                <span>{{
                  [1, 2, 3, 4, 8].includes(subjectId)
                    ? "-"
                    : topData.averageScore
                }}</span>
              </div>
              <div>赋分</div>
            </div>
          </div>
        </div>
        <div class="box" style="flex: 1">
          <div
            class="wrapper success"
            :style="{
              flex: 1,
              justifyContent: [1, 2, 3, 4, 8].includes(subjectId)
                ? 'space-around'
                : 'flex-start',
            }"
          >
            <div
              v-for="(item, index) in topData.topInfoRatioVOs"
              :key="index"
              class="row"
            >
              <div class="data">
                <span>{{ item.value }}%</span>
              </div>
              <div>{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template v-if="subjectId !== null && topData.year">
      <branch
        id="branch"
        :key="'branch' + subjectId + topData.segmentMaximum"
        :top-data="topData"
      />
      <test-paper
        id="test-paper"
        :key="'test-paper' + subjectId"
        :subject-id="subjectId"
        :top-data="topData"
      />
      <competition
        v-if="showStatClass()"
        id="competition"
        :key="'competition' + subjectId"
        :subject-id="subjectId"
      />
      <classStudent
        v-if="showStatClass()"
        :key="'classStudent' + subjectId"
        :subject-id="subjectId"
      />
      <student
        id="student"
        :key="'student' + subjectId"
        :subject-id="subjectId"
      />
      <development
        id="development"
        :key="'development' + subjectId"
        :subject-id="subjectId"
      />
    </template>
    <pageNav :menu-list="menuList" />
  </div>
</template>

<script>
import { examTypeOptions } from "@/core/util/constdata";
import branch from "./components/branch/index.vue";
import TestPaper from "./components/test-paper/index.vue";
import { Icon } from "ant-design-vue";
import competition from "./components/competition/index.vue";
import student from "./components/student/index.vue";
import classStudent from "./components/classGrade";
import development from "./components/development/index.vue";
import { getTopData } from "@/core/api/newExamAcademic/teaching";
import { getstatsubjectoption } from "@/core/api/academic/common";
import { getStore } from "@/core/util/store";
import pageNav from "@/views/academic/components/pageNav.vue";
import { exportTeachingReportNew } from "@/core/api/academic/teachingReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import { getTopInfo } from "@/core/api/newExamAcademic/union/teaching";

export default {
  name: "",
  components: {
    Icon,
    branch,
    TestPaper,
    competition,
    student,
    classStudent,
    development,
    pageNav,
    exportBtn,
  },
  data() {
    return {
      topData: {
        type: 99,
      },
      requestFunc: exportTeachingReportNew,
      subjectList: [],
      subjectId: null,
      schoolName: "",
      menuList: [
        {
          id: "branch",
          name: "成绩分布",
        },
        {
          id: "test-paper",
          name: "试卷分析",
        },
        {
          id: "competition",
          name: "班级竞争力",
        },
        {
          id: "student",
          name: "重点关注学生",
        },
        {
          id: "development",
          name: "发展性分析",
        },
      ],
    };
  },
  mounted() {
    this.schoolName = getStore({ name: "userInfo" }).schoolName;
    if (this.$route.query.examType == 1) {
      this.schoolName = this.$route.query.schoolName;
    } else {
      this.schoolName = getStore({ name: "userInfo" }).schoolName;
    }
    this.getCondition();
  },
  methods: {
    showStatClass() {
      if (this.$route.query.examType == 1) {
        return this.topData.statClass == 1;
      }
      return true;
    },
    async getCondition() {
      const res = await getstatsubjectoption({
        id: this.$route.query.id,
        requestPage: 2,
        hasTotal: 0,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.subjectList = res.data.data;
      if (res.data.data.length) {
        this.subjectId = res.data.data[0].id;
        this.getData();
      }
    },
    async getData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await getTopInfo({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getTopData({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      this.topData = { ...res.data.data };

      if (this.$route.query.examType == 1 && this.topData.statClass != 1) {
        this.menuList = this.menuList.filter(
          (item) => item.name !== "班级竞争力"
        );
      }
    },
    getExamType(type) {
      if (type) {
        const data = examTypeOptions.find((item) => item.value === type);
        return data.label;
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.subjects {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  .subject {
    box-sizing: border-box;
    padding: 6px 18px;
    background: transparent;
    cursor: pointer;
    color: #0a1119;
    border-radius: 16px;
    border: 1px solid transparent;
    margin-bottom: 18px;
  }
  .subject + .subject {
    margin-left: 18px;
  }
  .subject-active {
    color: #2474ed;
    border-color: #2474ed;
    background-color: #f3f8ff;
  }
}
.dashboard {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  .box {
    display: flex;
    align-items: center;
    padding: 0 !important;
    .wrapper {
      color: #3e4551;
      font-size: 12px;
      border-radius: 8px;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      height: 100%;
      padding: 0 16px;
      width: fit-content;
      .row {
        min-width: 55px;
        .data {
          margin-bottom: 8px;
          font-size: 12px;
          span {
            font-size: 18px;
            font-weight: 600;
          }
        }
      }
      .row + .row {
        margin-left: 8px;
      }
    }
    .box-title + .wrapper {
      margin-left: 4px;
    }
    .box-title {
      writing-mode: vertical-lr;
      color: #3e4551;
      font-size: 12px;
      border-radius: 8px;
      box-sizing: border-box;
      padding: 14px 12px;
      width: fit-content;
      height: 89px;
      white-space: nowrap;
      text-align-last: justify;
    }
  }
  .box + .box {
    margin-left: 18px;
  }
}
.primary {
  background: #f3f8ff;
  .data {
    color: #2474ed;
  }
}
.warning {
  background: #fff8f3;
  .data {
    color: #ff7a05;
  }
}
.success {
  background: #f3fcf8;
  .data {
    color: #2bb472;
  }
}
.a-tabs {
  width: 100%;
}
::v-deep .ant-tabs-ink-bar {
  display: none !important;
}
::v-deep .ant-tabs-bar {
  border-bottom: none;
}
::v-deep .ant-tabs-tab {
  height: 32px;
  line-height: 32px;
  padding: 0 16px;
}
::v-deep .ant-tabs-tab-active {
  border: 1px solid #2474ed;
  text-shadow: 0 0 0.25px currentColor;
  border-radius: 16px;
  color: #2474ed;
  background: #f3f8ff;
}
::v-deep .ant-tabs-nav-wrap {
  margin-bottom: 0;
}
@import "../new-exam.module.scss";
</style>
