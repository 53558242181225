import request from "@/core/services/axios";
export const getTopInfo = (params) => {
  // 校级报告-考情概述-组合竞争力-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreporttopinfo`,
    method: "GET",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getresearchreportscoredistribution = (params) => {
  // 教研报告-成绩分布-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportscoredistribution`,
    method: "POST",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetresearchreportpaperanalypaperquality = (params) => {
  // 教研报告-试卷分析-试卷命题质量【试卷-难度-区分度】-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportpaperanalypaperquality`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetresearchreportpaperanalyquestionquality = (params) => {
  // 教研报告-试卷分析-试题命题质量【题号-难度-区分度】-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportpaperanalyquestionquality`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetresearchreportpaperanalyquestiondiagquestionoverall = (
  params
) => {
  // 教研报告-试卷分析-试题命题质量【题号-难度-区分度】-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportpaperanalyquestiondiagquestionoverall`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const Uniongetresearchreportpaperanalyquestiondiagquestionsingle = (
  params
) => {
  // 教研报告-试卷分析-试题诊断-题目-单道试题情况-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportpaperanalyquestiondiagquestionsingle`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetresearchreportpaperanalyquestiondiagquestionscorerate = (
  params
) => {
  // 教研报告-试卷分析-试题诊断-题目-各班各题得分率情况-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportpaperanalyquestiondiagquestionscorerate`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const paperquestionlist = (params) => {
  // 获取单科的试题列表-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getpaperquestionlist`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const Uniongetresearchreportclasscompetitionindicator = (params) => {
  // 教研报告-班级竞争力-班级指标对比-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportclasscompetitionindicator`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const unionGetresearchreportclasscompetitionscoredistribution = (
  params
) => {
  // 教研报告-班级竞争力-班级成绩分布-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportclasscompetitionscoredistribution`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getresearchreportfocusonstudentwave = (params) => {
  // 教研报告-重点关注学生-波动生-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportfocusonstudentwave`,
    method: "post",
    data: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getresearchreportfocusonstudenttoporbackward = (params) => {
  // 教研报告-重点关注学生-拔尖生-后进生-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportfocusonstudenttoporbackward`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getresearchreportfocusonstudenttrack = (params) => {
  // 教研报告-重点关注学生-跟踪生-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportfocusonstudenttrack`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
export const getresearchreportdevelopanalysis = (params) => {
  // 教研报告-发展性分析-联考新高考
  return request({
    url: `/report/statunionnewgaokaoresearchreport/getresearchreportdevelopanalysis`,
    method: "get",
    params: params,
    headers: {
      "VIEW-SCHOOL-ID": params.viewSchoolId,
    },
  });
};
