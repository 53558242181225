<template>
  <div ref="branchClass" v-lazyChart="{ fn: typeChange }" class="container">
    <div class="sub-title">班级考试表现</div>
    <div class="filter">
      <div class="filter-item">
        数据源：
        <a-radio-group
          v-model="search.dataSource"
          name="radioGroup"
          @change="dataSourceChange"
        >
          <a-radio :value="1"> 原始分 </a-radio>
          <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" :value="2">
            赋分
          </a-radio>
        </a-radio-group>
      </div>
      <div class="range">
        <template v-if="search.type === '0'">
          分数段区间：
          <span>上限：</span>
          <a-input v-model="search.high" style="width: 64px" />
          <span style="margin: 0 18px">分</span>
          <span>下限：</span>
          <a-input v-model="search.low" style="width: 64px" />
          <span style="margin: 0 18px">分</span>
          <span>间隔：</span>
          <a-input v-model="search.interval" style="width: 64px" />
          <span style="margin: 0 18px">分</span>
        </template>
        <template v-if="search.type === '1'">
          排名段区间：

          <a-input v-model="search.high" style="width: 64px" />
          <span style="margin: 0 18px">名 -</span>

          <a-input v-model="search.low" style="width: 64px" />
          <span style="margin: 0 18px">名</span>
          <span>间隔：</span>
          <a-input v-model="search.interval" style="width: 64px" />
          <span style="margin: 0 18px">名</span>
        </template>
        <a-button type="primary" @click="getData">确定</a-button>
      </div>
    </div>
    <div v-if="$route.query.examType != 1" class="operate">
      <a-radio-group
        v-model="search.type"
        button-style="solid"
        @change="typeChange"
      >
        <a-radio-button value="0"> 分数段 </a-radio-button>
        <a-radio-button value="1"> 排名 </a-radio-button>
      </a-radio-group>
    </div>
    <heat-vue
      v-if="chartData.length"
      :key="chartKey"
      :source="heatData"
      :tooltip="tooltip"
      :item-height="50"
    />
    <div class="tip" style="margin-top: 18px">
      <div>
        <div>
          横坐标为班级，按照班级名称排序，纵坐标为分数（或排名）区间，色块数值为该班级该分数（或排名）区间内的人数，人数越多则颜色越深，如出现空白区域，
        </div>
        <div>
          则该班级该分数（或排名）区域内没有人数，如分数（或排名）中段区域连续出现多个空白，则代表该班级该分数（或排名）范围内学生断档。
        </div>
        <div class="wrapper">
          <div>注：</div>
          <div>
            <div>
              以上数据结果均以{{
                search.dataSource == 1 ? "原始分" : "赋分"
              }}进行统计运算
            </div>
            <div>以上数据均不包括不参与统计的考生成绩</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import heatVue from "@/views/academic/charts/heat.vue";
import { classscoredistribution } from "@/core/api/newExamAcademic/teaching";
import { unionGetresearchreportclasscompetitionscoredistribution } from "@/core/api/newExamAcademic/union/teaching";
export default {
  name: "ClassGrade",
  components: {
    heatVue,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    return {
      search: {
        dataSource: 1,
        high: undefined,
        low: undefined,
        interval: undefined,
        type: "0",
      },
      type: "1",
      heatData: {
        xAxis: [],
        yAxis: [],
        data: [],
      },
      chartData: [],
      chartKey: Math.random(),
      tooltip: {
        trigger: "item",
        backgroundColor: "rgba(0,0,0,0.7)",
        borderColor: "rgba(0,0,0,0.7)",
        textStyle: {
          color: "#fff",
        },
        axisPointer: {
          type: "shadow",
          shadowStyle: {
            color: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
      classSegmentVos: [],
    };
  },
  mounted() {
    if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
      this.search.dataSource = 1;
    }
  },
  methods: {
    async getData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await unionGetresearchreportclasscompetitionscoredistribution({
          ...this.search,
          dataSource: this.search.dataSource,
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await classscoredistribution({
          ...this.search,
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      this.chartData = res.data.data;
      let key =
        this.$route.query.examType == 1
          ? "researchReportClassCompetitionScoreDistributionClassSegmentVOs"
          : "classSegmentVOs";
      if (res.data.data.length) {
        let classVo = res.data.data[0][key];

        this.classSegmentVos = classVo;
        this.heatData.xAxis = res.data.data.map((item) => item.name);
        this.heatData.yAxis = classVo.map((item) => item.name);
        let data = this.chartData.flatMap((item, index) => {
          let itemClass = item[key];
          return itemClass.map((it, ind) => [index, ind, it.total || null]);
        });
        let all = data.map((item) => item[2]);

        this.heatData.data = [...data];
        this.heatData.max = Math.max(...all);
        this.heatData.min = Math.min(...all);
        this.chartKey = Math.random();
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.branchClass);
        });
      }
    },
    dataSourceChange() {
      this.typeChange();
    },
    typeChange(loadingInstance, io) {
      if (this.search.type === "0") {
        let { segmentMaximum, segmentMinimum, segmentGap } =
          this.$parent.topData;
        this.search.high = segmentMaximum;
        this.search.low = segmentMinimum;
        this.search.interval = segmentGap;
        this.$forceUpdate();
        this.getData();
      } else if (this.search.type === "1") {
        let { rankMinimum, rankMaximum, rankGap } = this.$parent.topData;
        this.search.high = rankMaximum;
        this.search.low = rankMinimum;
        this.search.interval = rankGap;
        this.$forceUpdate();
        this.getData();
      }
      this.closeLoading(loadingInstance, io);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.filter {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .filter-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 18px;
    span {
      margin: 0 8px;
    }
  }
}
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
.range {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  span {
    margin: 0 8px;
  }
}
</style>
