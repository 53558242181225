<template>
  <div ref="compeition" v-lazyChart="{ fn: getClassList }" class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 2,
        subjectId: subjectId,
      }"
    >
      <div class="title">班级竞争力</div>
    </exportBtn>
    <div class="filter">
      <div class="classNum">
        选择{{ getClassType(subjectId) }}班：
        <classNums
          :key="classNumKey"
          ref="classNums"
          placeholder="选择班级"
          :class-num-list="classNumsList"
          @change="classNumChange"
        />
      </div>
      <a-radio-group
        v-model="category"
        button-style="solid"
        @change="categoryChange"
      >
        <a-radio-button :value="1"> 均分分析 </a-radio-button>
        <a-radio-button v-if="$route.query.examType != 1" :value="2">
          四分位分析
        </a-radio-button>
        <a-radio-button v-if="ratios.length" :value="3"> 比 率 </a-radio-button>
        <a-radio-button v-if="topSections.length" :value="4">
          前 N 名
        </a-radio-button>
        <a-radio-button :value="5"> 更多指标 </a-radio-button>
      </a-radio-group>
    </div>
    <div v-if="category != 1 && category != 2" class="filter">
      <div>
        数据源：
        <a-radio-group
          v-model="dataSource"
          name="radioGroup"
          @change="dataSourceChange"
        >
          <a-radio :value="1"> 原始分 </a-radio>
          <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" :value="2">
            赋分
          </a-radio>
        </a-radio-group>
      </div>
      <a-select
        v-if="[3, 4, 5].includes(category)"
        v-model="value"
        not-found-content="暂无数据"
        placeholder="请选择"
        style="min-width: 200px"
        @change="handleChange"
      >
        <a-select-option
          v-for="(item, index) in selectOptions"
          :key="index"
          :value="item.value"
        >
          {{ item.label }}
        </a-select-option>
      </a-select>
    </div>
    <template v-if="category !== 2">
      <chart
        v-if="defineOptions"
        :key="chartKey"
        :define-options="defineOptions"
        :series="series"
        :data-zoom="10"
      />
      <no-data v-else />
    </template>
    <template v-else>
      <box-plot
        v-if="defineOptions"
        :key="chartKey"
        show-tooltip
        :x-axis="BoxPlotXAxis"
        :define-options="defineOptions"
      ></box-plot>
      <no-data v-else />
    </template>

    <exTable
      :key="'exTable' + chartKey"
      :columns="column"
      :meger-list="['本校', '整体', '年级均值', '学科高位班级']"
      style="margin: 18px 0"
      :col-span-num="colSpanNum"
      :data-source="tableData"
    />
    <div class="tip">
      <template v-if="type === 1"
        >注：以上数据均不包括不参与统计的考生成绩
      </template>
      <template v-else>
        <div class="wrapper">
          <div>注：</div>
          <div>
            <template v-if="category === 1">
              <div>
                <span v-if="![1, 2, 3, 4, 8].includes(subjectId)"
                  >除赋分均分外，
                </span>
                以上数据结果均以原始分进行统计运算
              </div>
              <div>以上数据均不包括不参与统计的考生成绩</div>
            </template>
            <template v-if="category === 2">
              <div>
                <span v-if="![1, 2, 3, 4, 8].includes(subjectId)">
                  除赋分均分外，
                </span>
                以上数据结果均以原始分进行统计运算
              </div>
              <div>以上数据均不包括不参与统计的考生成绩</div>
            </template>
            <template v-if="category === 3">
              <div>
                以上数据结果均以{{
                  dataSource == 1 ? "原始分" : "赋分"
                }}进行统计运算
              </div>
              <div>以上数据均不包括不参与统计的考生成绩</div>
            </template>
            <template v-if="category === 4">
              <div>
                以上数据结果均以{{
                  dataSource == 1 ? "原始分" : "赋分"
                }}进行统计运算
              </div>
              <div>以上数据均不包括不参与统计的考生成绩</div>
            </template>
            <template v-if="category == 5">
              <div>
                以上数据结果采用{{
                  dataSource === 1 ? "原始分" : "赋分"
                }}进行统计运算
              </div>
              <div>
                方差
                各个数据与平均数之差的平方的和的平均数，用来衡量一批数据的波动大小(即这批数据偏离平均数的大小)
              </div>
              <div>
                标准差 方差的算术平方根
                表示一组数值的离散程度，标准差越大，表示该组数值与均值的偏差越大
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { getresearchreportclasscompetitionindicator } from "@/core/api/newExamAcademic/teaching";
import { Uniongetresearchreportclasscompetitionindicator } from "@/core/api/newExamAcademic/union/teaching";
import { getstatclassoption } from "@/core/api/academic/common";
import chart from "@/views/newExamAcademic/components/charts.vue";
import BoxPlot from "@/views/newExamAcademic/components/BoxPlot.vue";
import exTable from "../../../components/exTable.vue";
import classNums from "../../../components/classNums.vue";
import { getClassType } from "../../../func";
import {
  avgCol,
  quartileCol,
  ratioCol,
  topSectionCol,
  uniCols,
} from "./column";
import { exportTeachingReportNew } from "@/core/api/academic/teachingReport";
import exportBtn from "@/views/academic/components/exportBtn.vue";
export default {
  components: {
    classNums,
    chart,
    BoxPlot,
    exTable,
    exportBtn,
  },
  props: {
    subjectId: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      requestFunc: exportTeachingReportNew,
      classNumsList: [],
      classNumChecked: null,
      category: 1,
      defineOptions: {},
      series: [],
      chartKey: null,
      BoxPlotXAxis: [],
      type: 2,
      value: "",
      column: [],
      tableData: [],
      dataSource: 1,
      classNumKey: null,
      colSpanNum: 0,
      selectOptions: [],
      allData: [],
      moreCol: [
        {
          dataIndex: "classNum",
          align: "center",
          title: "班级",
        },
        {
          dataIndex: "teacherName",
          align: "center",
          title: "班主任",
        },
        {
          dataIndex: "statTotal",
          align: "center",
          title: "统计人数",
        },
        {
          dataIndex: "medianOri",
          align: "center",
          title: "中位数",
          customRender: (text, record) =>
            record["median" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "highestScoreOri",
          align: "center",
          title: "最高分",
          customRender: (text, record) =>
            record["highestScore" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "lowestScoreOri",
          align: "center",
          title: "最低分",
          customRender: (text, record) =>
            record["lowestScore" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "standardDeviationOri",
          align: "center",
          title: "标准差",
          customRender: (text, record) =>
            record["standardDeviation" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "varianceOri",
          align: "center",
          title: "方差",
          customRender: (text, record) =>
            record["variance" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "coefficientOfVariationOri",
          align: "center",
          title: "差异系数",
          customRender: (text, record) =>
            record[
              "coefficientOfVariation" + (this.dataSource === 1 ? "Ori" : "")
            ],
        },
        {
          dataIndex: "discriminationOri",
          align: "center",
          title: "区分度",
          customRender: (text, record) =>
            record[
              "discrimination" + (this.dataSource === 1 ? "Ori" : "")
            ].toFixed(2),
        },
        {
          dataIndex: "scoreRateOri",
          align: "center",
          title: "得分率",
          customRender: (text, record) =>
            record["scoreRate" + (this.dataSource === 1 ? "Ori" : "")],
        },
        {
          dataIndex: "overAverageRateOri",
          align: "center",
          title: "超均率",
          customRender: (text, record) =>
            record["overAverageRate" + (this.dataSource === 1 ? "Ori" : "")],
        },
      ],
      unionMoreCols: [],
      topSections: [],
      ratios: [],
      otherSelect: [
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "方差",
          value: "variance",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
        {
          label: "得分率",
          value: "scoreRate",
        },
        {
          label: "超均率",
          value: "overAverageRate",
        },
        {
          label: "区分度",
          value: "discrimination",
        },
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
      ],
      unionSelect: [
        {
          label: "最高分",
          value: "highestScore",
        },
        {
          label: "最低分",
          value: "lowestScore",
        },
        {
          label: "中位数",
          value: "median",
        },
        // {
        //   label: "超均率",
        //   value: "overAverageRateUnion",
        // },
        {
          label: "超均率（校）",
          value: "overAverageRate",
        },
        {
          label: "超均率（整体）",
          value: "overAverageRateUnion",
        },
        {
          label: "标准差",
          value: "standardDeviation",
        },
        {
          label: "差异系数",
          value: "coefficientOfVariation",
        },
      ],
    };
  },
  mounted() {
    // this.getClassList();
    this.unionMoreCols = [
      {
        dataIndex: "classNum",
        title: "班级",
        align: "center",
        width: 85,
      },
      {
        dataIndex: "teacherName",
        title: "授课教师",
        align: "center",
        width: 100,
      },
      {
        dataIndex: "statTotal",
        title: "统计人数",
        align: "center",
        width: 100,
      },
      {
        dataIndex: "highestScoreOri",
        scopedSlots: {
          title: this.dataSource == 1 ? "highestScoreOri" : "highestScore",
        },
        align: "center",
        customRender: (text, record) =>
          record[this.dataSource == 1 ? "highestScoreOri" : "highestScore"],
      },
      {
        dataIndex: "lowestScoreOri",
        scopedSlots: {
          title: this.dataSource == 1 ? "lowestScoreOri" : "lowestScore",
        },
        align: "center",
        customRender: (text, record) =>
          record[this.dataSource == 1 ? "lowestScoreOri" : "lowestScore"],
      },
      {
        dataIndex: "rangeScoreOri",
        scopedSlots: {
          title: this.dataSource == 1 ? "rangeScoreOri" : "rangeScore",
        },
        align: "center",
        customRender: (text, record) =>
          record[this.dataSource == 1 ? "rangeScoreOri" : "rangeScore"],
      },
      {
        dataIndex: "medianOri",
        align: "center",
        scopedSlots: { title: this.dataSource == 1 ? "medianOri" : "median" },
        customRender: (text, record) =>
          record[this.dataSource == 1 ? "medianOri" : "median"],
      },
      {
        dataIndex: "overAverageRateUnionOri",
        align: "center",
        scopedSlots: { title: "unionOverAverageRateOri" },
        customRender: (text, record) =>
          record[
            this.dataSource == 1
              ? "overAverageRateUnionOri"
              : "overAverageRateUnion"
          ],
      },
      {
        dataIndex: "overAverageRateOri",
        align: "center",
        scopedSlots: { title: "schoolOverAverageRateOri" },
        customRender: (text, record) => record["overAverageRateOri"],
      },
      {
        dataIndex: "standardDeviationOri",
        scopedSlots: { title: "standardDeviationOri" },
        align: "center",
        customRender: (text, record) =>
          record[
            this.dataSource == 1 ? "standardDeviationOri" : "standardDeviation"
          ],
      },
      {
        dataIndex: "coefficientOfVariationOri",
        scopedSlots: {
          title:
            this.dataSource == 1
              ? "coefficientOfVariationOri"
              : "coefficientOfVariation",
        },
        align: "center",
        customRender: (text, record) =>
          record[
            this.dataSource == 1
              ? "coefficientOfVariationOri"
              : "coefficientOfVariation"
          ],
      },
    ];
  },
  methods: {
    getClassType: getClassType,
    async getData() {
      let res;

      if (this.$route.query.schoolId) {
        res = await Uniongetresearchreportclasscompetitionindicator({
          id: this.$route.query.id,
          classNums: this.classNumChecked.toString(),
          isAllClass: 0,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getresearchreportclasscompetitionindicator({
          id: this.$route.query.id,
          classNums: this.classNumChecked.toString(),
          isAllClass: 0,
          subjectId: this.subjectId,
        });
      }

      this.tableData = res.data.data;
      this.allData = [...res.data.data];
      if (this.tableData.length) {
        this.topSections = res.data.data[0].topSections;
        this.ratios = res.data.data[0].ratios;
      }
      this.categoryChange();
    },
    dataSourceChange() {
      let run = {
        1: () => this.setAvg(),
        2: () => this.setQuartile(),
        3: () => this.setRatioChart(),
        4: () => this.setTopsectionChart(),
        5: () => this.setMoreChart(),
      };
      run[this.category]();
    },
    classNumChange(val) {
      this.classNumChecked = val;
      this.getData();
    },
    handleChange() {
      if (this.category == 3) {
        this.setRatioChart();
      } else if (this.category == 4) {
        this.setTopsectionChart();
      } else if (this.category == 5) {
        this.setMoreChart();
      }
    },
    async getClassList(loadingInstance, io) {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        requestPage: 2,
        subjectId: this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumsList = res.data.data.map((item) => item.name);
      this.classNumChecked = res.data.data.map((item) => item.name);
      this.classNumKey = Math.random();
      this.getData();
      this.closeLoading(loadingInstance, io);
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.compeition);
        });
      }
    },
    categoryChange() {
      this.defineOptions = null;
      this.series = [];
      this.selectOptions = [];
      this.value = null;
      let run = {
        1: () => this.setAvg(),
        2: () => this.setQuartile(),
        3: () => this.setRatio(),
        4: () => this.setTop(),
        5: () => this.setMore(),
      };
      run[this.category]();
    },
    setAvg() {
      if (this.$route.query.examType == 1) {
        let cols = uniCols.avg;
        if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
          cols = uniCols.avg.filter((item) => item.dataIndex != "averageScore");
        }
        this.colSpanNum = 3;
        if (this.$parent.topData.isSchoolDisplayReportClassRank == 0) {
          cols = cols.filter((item) => item.title !== "校排名");
          this.colSpanNum = 2;
        }
        if (this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 0) {
          cols = cols.filter(
            (item) => item.dataIndex !== "overAverageRateUnionOri"
          );
        }
        this.column = cols;
      } else {
        this.column = avgCol;
      }
      let grade = this.tableData.find((item) =>
        this.$route.query.examType == 1
          ? ["本校", "学校"].includes(item.classNum)
          : item.classNum == "年级均值"
      );
      let data = this.tableData.filter((item) =>
        this.$route.query.examType == 1
          ? !["学科高位班级", "本校", "整体", "学校"].includes(item.classNum)
          : item.classNum !== "年级均值"
      );
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...data.map((item) => item.classNum)],
        },
      };
      this.series = [
        {
          type: "bar",
          name: "班级均分（原始分）",
          color: "#619AF1",
          data: [...data.map((item) => item.averageScoreOri)],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },
        {
          type: "line",
          name: "年级均分（原始分）",
          color: "#FF9268",
          data: [...data.map(() => grade.averageScoreOri)],
        },
      ];

      if (![1, 2, 3, 4, 8].includes(this.subjectId)) {
        this.series.splice(1, 0, {
          type: "bar",
          name: "班级均分（赋分）",
          color: "#93BEFD",
          data: [...data.map((item) => item.averageScore)],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        });
        this.series.push({
          type: "line",
          name: "年级均分（赋分）",
          color: "#F65252",
          data: [...data.map(() => grade.averageScore)],
        });
      }
      this.chartKey = Math.random();
      this.tableKey = Math.random();
    },
    setQuartile() {
      this.chartKey = Math.random();
      let noGrade = this.tableData.filter(
        (item) => item.classNum !== "年级均值"
      );
      let grade = this.tableData.find((item) => item.classNum == "年级均值");
      this.BoxPlotXAxis = [...noGrade.map((item) => item.classNum)];
      this.column = [...quartileCol];
      this.colSpanNum = 3;
      let data = [
        ...noGrade.map((item) => {
          return [
            item.lowerWhisker,
            item.lowerQuartile,
            item.medianOri,
            item.upperQuartile,
            item.upperWhisker,
          ];
        }),
      ];
      let arr = data.flat();
      let result = arr.filter((item) => item);
      if (result.length) {
        this.defineOptions = {
          legend: {
            data: [
              { name: "年级最高分" },
              { name: "年级最低分" },
              { name: "年级中位数" },
            ],
          },
          tooltip: {
            formatter: (params) => {
              const classNum = params.data[0],
                upperWhisker = params.data[5],
                medianOri = params.data[3],
                lowerWhisker = params.data[1];
              return (
                classNum +
                "<br />" +
                "上限：" +
                upperWhisker +
                "<br />" +
                "中位数：" +
                medianOri +
                "<br />" +
                "下限：" +
                lowerWhisker
              );
            },
          },
          grid: {
            containLabel: true,
          },
          dataZoom: [
            {
              type: "slider",
              height: 20,
              start: 0,
              end: 10,
            },
          ],
          dataset: [
            {
              source: [...data],
            },
            {
              transform: {
                type: "boxplot",
                config: {},
              },
            },
            {
              fromDatasetIndex: 1,
              fromTransformResult: 1,
            },
          ],
          series: [
            {
              name: " ",
              type: "boxplot",
              datasetIndex: 1,
              itemStyle: {
                color: "#7AB5EF",
              },
              boxWidth: [10, 20],
            },
            {
              name: "",
              type: "scatter",
              datasetIndex: 2,
              itemStyle: {
                color: "#4589EF",
              },
              symbolSize: 4,
            },
            {
              type: "line",
              name: "年级最高分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              color: "#FF0000",
              // color:'#31B400'
              data: [...noGrade.map(() => grade.highestScoreOri)],
            },
            {
              type: "line",
              name: "年级最低分",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...noGrade.map(() => grade.lowestScoreOri)],
              color: "#ED6024",
              // color:'#2D49FF'
            },
            {
              type: "line",
              name: "年级中位数",
              symbol: "none",
              lineStyle: {
                type: "dashed",
              },
              data: [...noGrade.map(() => grade.medianOri)],
              color: "#FFAB00",
              // color:'#00CBC7'
            },
          ],
        };
      } else {
        this.defineOptions = null;
      }
    },
    setRatio() {
      this.ratios = [];
      if (this.$route.query.examType == 1) {
        this.colSpanNum = 2;
      }
      if (this.tableData.length) {
        this.ratios = this.tableData[0].ratios.map((item) => ({
          label: item.name,
          value: item.name,
        }));
        this.selectOptions = this.ratios;
        this.value = this.ratios[0].value;
        this.column = [
          ...ratioCol,
          ...this.ratios.map((item, index) => {
            if (this.$route.query.examType == 1) {
              return {
                title: item.label,
                dataIndex: "ratios" + index,
                align: "center",
                children: [
                  {
                    title: "人数",
                    dataIndex: "ratios" + index + "totalOri",
                    align: "center",
                    customRender: (text, record) =>
                      this.dataSource === 1
                        ? record.ratios[index].totalOri
                        : record.ratios[index].total,
                  },
                  {
                    title: "占比（%）",
                    dataIndex: "ratios" + index + "proportionOri",
                    align: "center",
                    customRender: (text, record) =>
                      this.dataSource === 1
                        ? record.ratios[index].proportionOri
                        : record.ratios[index].proportion,
                  },
                ],
              };
            }
            return {
              title: item.label + "（人数/占比）",
              dataIndex: "ratios" + index,
              width: 85,
              align: "center",
              customRender: (text, record) => {
                if (this.dataSource === 1) {
                  return (
                    record.ratios[index].totalOri +
                    "/" +
                    record.ratios[index].proportionOri +
                    "%"
                  );
                } else {
                  return (
                    record.ratios[index].total +
                    "/" +
                    record.ratios[index].proportion +
                    "%"
                  );
                }
              },
            };
          }),
        ];
      }
      this.setRatioChart();
    },
    setTop() {
      this.topSection = [];
      let data = this.tableData.filter((item) => item.classNum !== "年级均值");
      this.column = [];
      if (this.$route.query.examType == 1) {
        this.colSpanNum = 2;
      }
      if (this.tableData.length) {
        this.topSection = this.tableData[0].topSections;
        if (this.topSection.length) {
          this.column = [
            ...topSectionCol,
            ...this.topSection.map((item, index) => {
              if (this.$route.query.examType == 1) {
                return {
                  title: item.name,
                  dataIndex: "topSection" + index,
                  align: "center",
                  children: [
                    {
                      title: "人数",
                      dataIndex: "topSection" + index + "totalOri",
                      align: "center",
                      customRender: (text, record) =>
                        this.dataSource === 1
                          ? record.topSections[index].totalOri
                          : record.topSections[index].total,
                    },
                    {
                      title: "占比（%）",
                      dataIndex: "topSections" + index + "proportionOri",
                      align: "center",
                      customRender: (text, record) =>
                        this.dataSource === 1
                          ? record.topSections[index].proportionOri
                          : record.topSections[index].proportion,
                    },
                  ],
                };
              }
              return {
                title: item.name + "（人数/占比）",
                dataIndex: "topSection" + index,
                align: "center",
                customRender: (text, record) => {
                  if (this.dataSource === 1) {
                    return (
                      record.topSections[index].totalOri +
                      "/" +
                      record.topSections[index].proportionOri +
                      "%"
                    );
                  } else {
                    return (
                      record.topSections[index].total +
                      "/" +
                      record.topSections[index].proportion +
                      "%"
                    );
                  }
                },
              };
            }),
          ];
          this.defineOptions = {
            xAxis: {
              type: "category",
              axisTick: {
                show: false,
              },
              data: [...data.map((item) => item.classNum)],
            },
            color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
          };
          this.value = this.topSection[0].name;
          this.selectOptions = this.topSection.map((item) => ({
            label: item.name,
            value: item.name,
          }));
        }
      }

      this.setTopsectionChart();
    },
    setMore() {
      if (this.$route.query.examType == 1) {
        this.colSpanNum = 2;
      }
      this.column =
        this.$route.query.examType == 1
          ? [...this.unionMoreCols]
          : [...this.moreCol];
      this.selectOptions =
        this.$route.query.examType == 1 ? this.unionSelect : this.otherSelect;
      this.value = "standardDeviation";
      this.setMoreChart();
    },
    setRatioChart() {
      const ratioKey = "ratios";

      const classData = this.tableData.filter(
        (item) =>
          !["学科高位班级", "本校", "学校", "整体", "年纪均值"].includes(
            item.classNum
          )
      );
      const gradeData = this.tableData.find((item) =>
        ["年级均值", "本校", "学校"].includes(item.classNum)
      );
      const totalData = this.tableData.find((item) =>
        ["整体"].includes(item.classNum)
      );
      const ratios = classData.map((item) =>
        item[ratioKey].find((it) => it.name == this.value)
      );
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...classData.map((item) => item.classNum)],
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
      };
      this.series = [
        {
          name: "班级",
          type: "bar",
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
          data: ratios.map((item) =>
            this.dataSource == 1 ? item.proportionOri : item.proportion
          ),
        },
        {
          name: this.$route.query.examType == 1 ? "本校" : "年级",
          type: "line",
          data: classData.map(() => {
            const data = gradeData[ratioKey].find(
              (it) => it.name == this.value
            );
            return this.dataSource == 1 ? data.proportionOri : data.proportion;
          }),
        },
      ];
      if (
        this.$route.query.examType == 1 &&
        this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.series.push({
          name: "整体",
          type: "line",
          data: classData.map(() => {
            const data = totalData[ratioKey].find(
              (it) => it.name == this.value
            );
            return this.dataSource == 1 ? data.proportionOri : data.proportion;
          }),
        });
      }
      this.chartKey = Math.random();
    },
    setTopsectionChart() {
      const classData = this.tableData.filter(
        (item) =>
          !["学科高位班级", "本校", "学校", "整体", "年纪均值"].includes(
            item.classNum
          )
      );
      const gradeData = this.tableData.find((item) =>
        ["年级均值", "本校", "学校"].includes(item.classNum)
      );
      const totalData = this.tableData.find((item) =>
        ["整体"].includes(item.classNum)
      );
      const ratios = classData.map((item) =>
        item.topSections.find((it) => it.name == this.value)
      );
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...classData.map((item) => item.classNum)],
        },
        color: ["#7AB5EF", "#FF9776", "#6EDBA7", "#FFD766"],
      };
      this.series = [
        {
          name: "班级",
          type: "bar",
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
          data: ratios.map((item) =>
            this.dataSource == 1 ? item.proportionOri : item.proportion
          ),
        },
        {
          name: this.$route.query.examType == 1 ? "本校" : "年级",
          type: "line",
          data: classData.map(() => {
            const data = gradeData.topSections.find(
              (it) => it.name == this.value
            );
            return this.dataSource == 1 ? data.proportionOri : data.proportion;
          }),
        },
      ];
      if (
        this.$route.query.examType == 1 &&
        this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.series.push({
          name: "整体",
          type: "line",
          data: classData.map(() => {
            const data = totalData.topSections.find(
              (it) => it.name == this.value
            );
            return this.dataSource == 1 ? data.proportionOri : data.proportion;
          }),
        });
      }
      this.chartKey = Math.random();
    },
    setMoreChart() {
      let data = this.tableData.filter(
        (item) =>
          !["年级均值", "学校", "整体", "学科高位班级", "本校"].includes(
            item.classNum
          )
      );
      let grade = this.tableData.find((item) =>
        ["年级均值", "学校", "本校"].includes(item.classNum)
      );
      this.defineOptions = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          data: [...data.map((item) => item.classNum)],
        },
      };
      this.series = [
        {
          type: "bar",
          name: this.dataSource === 1 ? "班级(原始分)" : "班级(赋分)",
          color: "#619AF1",
          data: [
            ...data.map((item) => {
              if (this.value === "discrimination") {
                let data =
                  item[
                    this.value + (this.dataSource == 1 ? "Ori" : "")
                  ].toFixed(2);
                return data < 0
                  ? {
                      value: data,
                      itemStyle: {
                        color: "#F56C6B",
                      },
                      label: { position: "bottom" },
                    }
                  : data;
              }
              let otherData =
                item[this.value + (this.dataSource == 1 ? "Ori" : "")];
              return otherData < 0
                ? {
                    value: otherData,
                    label: { position: "bottom" },
                    itemStyle: {
                      color: "#F56C6B",
                    },
                  }
                : otherData;
            }),
          ],
          barMaxWidth: 40,
          label: {
            position: "top",
            color: "inherit",
            show: true,
          },
        },
        {
          type: "line",
          name: this.dataSource === 1 ? "年级(原始分)" : "年级(赋分)",
          color: "#F65252",
          data: [
            ...data.map(() => {
              if (this.value === "discrimination") {
                return grade[
                  this.value + (this.dataSource == 1 ? "Ori" : "")
                ].toFixed(2);
              }
              return grade[this.value + (this.dataSource == 1 ? "Ori" : "")];
            }),
          ],
        },
      ];
      this.chartKey = Math.random();
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
}
</style>
