<template>
  <div class="diagnosis">
    <div class="sub-title">试卷诊断</div>
    <div class="radio">
      <a-radio-group
        v-model="category"
        button-style="solid"
        @change="radioChange"
      >
        <a-radio-button v-if="topData.hasKnowledge" :value="1"
          >知识点
        </a-radio-button>
        <a-radio-button :value="2"> 题目 </a-radio-button>
      </a-radio-group>
    </div>
    <template v-if="subjectId !== null">
      <diagnosis-point
        v-if="category === 1"
        :key="'diagnosis-point' + subjectId"
        :subject-id="subjectId"
        :top-data="topData"
      />
      <diagnosis-questions
        v-if="category === 2"
        :key="'diagnosis-questions' + subjectId"
        :subject-id="subjectId"
        :top-data="topData"
      />
    </template>
  </div>
</template>

<script>
import diagnosisQuestions from "./diagnosis-questions.vue";
import diagnosisPoint from "./diagnosis-point.vue";
export default {
  name: "Diagnosis",
  components: {
    diagnosisQuestions,
    diagnosisPoint,
  },
  props: {
    subjectId: {
      type: [Number, String],
      default: "",
    },
    topData: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      category: 2,
    };
  },

  mounted() {},
  methods: {
    radioChange() {},
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../new-exam.module.scss";

.diagnosis {
  .radio {
    margin: 18px 0;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
