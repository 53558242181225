<template>
  <el-popover v-model="visible" placement="bottom" width="300">
    <el-button type="text" @click="handleCheckAllChange">全选</el-button>
    <el-button type="text" @click="cancleChange">取消</el-button>
    <div style="margin: 15px 0"></div>
    <el-checkbox-group v-model="checkedList" @change="handleCheckedItemChange">
      <el-checkbox
        v-for="item in allCheckItem"
        :key="item.id ? item.id : item.name ? item.name : item"
        :label="item"
        >{{ item.name || item }}</el-checkbox
      >
    </el-checkbox-group>
    <div style="text-align: right; margin: 0">
      <el-button @click="handleClose">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </div>
    <el-select
      slot="reference"
      ref="hideMenu"
      v-model="value"
      style="width: 112px"
      :placeholder="placeholder"
      @focus="selectFocus"
    >
    </el-select>
  </el-popover>
</template>

<script>
export default {
  name: "CheckComponent",
  props: {
    placeholder: {
      type: String,
      default: "请选择",
    },
    source: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: [Number, null],
      default: null,
    },
  },
  data() {
    return {
      visible: false,
      value: "",
      checkAll: false,
      checkedList: [],
      allCheckItem: [],
      isIndeterminate: true,
      cancle: false,
      older: [],
    };
  },
  watch: {
    source: {
      deep: true,
      handler(val) {
        this.allCheckItem = [...val];
        if (this.limit) {
          this.checkedList = [...val.slice(0, this.limit)];
        } else {
          this.checkedList = [...val];
        }
        this.old = [...val];
        this.$emit("change", this.checkedList);
      },
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.source) {
        this.allCheckItem = [...this.source];
        if (this.limit) {
          this.checkedList = [...this.source.slice(0, this.limit)];
        } else {
          this.checkedList = [...this.source];
        }
        this.old = [...this.source];
        this.$emit("change", this.checkedList);
      }
    });
  },
  methods: {
    handleCheckAllChange() {
      this.checkedList = this.allCheckItem;
    },
    handleCheckedItemChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.allCheckItem.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.allCheckItem.length;
    },
    selectFocus() {
      this.$refs.hideMenu.blur();
    },
    cancleChange() {
      this.checkedList = [];
    },
    handleClose() {
      this.visible = false;
    },
    submit() {
      this.$emit("change", this.checkedList);
      this.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-select {
  ::v-deep .el-input__inner {
    color: #3f87f4;
    background: #ecf3fe;
    border-color: #b2cffb;
  }
  ::v-deep .el-select__caret {
    color: #3f87f4;
  }
  ::v-deep input::-webkit-input-placeholder {
    //兼容WebKit browsers（Chrome的内核）
    color: #3f87f4;
  }
  ::v-deep input::-moz-placeholder {
    //Mozilla Firefox 4 to 18
    color: #3f87f4;
  }
  ::v-deep input::-moz-placeholder {
    //Mozilla Firefox 19+
    color: #3f87f4;
  }
}
.el-table {
  &::v-deep {
    .el-table__body-wrapper::-webkit-scrollbar {
      /*width: 0;宽度为0隐藏*/
      height: 10px;
    }
    .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 8px;
      height: 8px;
      background: #a1a1a1; //滚动条颜色
    }
    .el-table__body-wrapper::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(241, 240, 245, 0.2);
      background: #ececec; //滚动条背景色
      height: 8px;
    }
  }
}
::v-deep .el-table__fixed {
  height: calc(100% - 10px) !important;
}
</style>
