export const difficultCols = [
  {
    title: "分类",
    dataIndex: "name",
    width: 85,
    align: "center",
  },
  {
    title: "难度系数",
    dataIndex: "interval",
    width: 100,
    align: "center",
  },
  {
    title: "分值",
    dataIndex: "score",
    width: 75,
    align: "center",
  },
  {
    title: "占比（%）",
    dataIndex: "proportion",
    width: 120,
    align: "center",
  },
  {
    title: "客观题",
    dataIndex: "objectiveOrder",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "objectiveOrder" },
  },
  {
    title: "主观题",
    dataIndex: "subjectiveOrder",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "subjectiveOrder" },
  },
];
export const distinguishCols = [
  {
    title: "分类",
    dataIndex: "name",
    width: 85,
    align: "center",
  },
  {
    title: "难度系数",
    dataIndex: "interval",
    width: 100,
    align: "center",
  },
  {
    title: "分值",
    dataIndex: "score",
    width: 75,
    align: "center",
  },
  {
    title: "占比（%）",
    dataIndex: "proportion",
    width: 120,
    align: "center",
  },
  {
    title: "客观题",
    dataIndex: "objectiveOrder",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "objectiveOrder" },
  },
  {
    title: "主观题",
    dataIndex: "subjectiveOrder",
    width: 100,
    align: "center",
    scopedSlots: { customRender: "subjectiveOrder" },
  },
];
