// 校验报告
import request from "@/core/services/axios";
export function getresearchreporttopinfo(params) {
  // 获取教研报告顶部数据
  return request({
    url: `/report/statresearchreport/getresearchreporttopinfo`,
    method: "get",
    params: params,
  });
}
export function getstatInfo(params) {
  // 获取科目
  return request({
    url: `/report/stat/getstatinfo`,
    method: "get",
    params: params,
  });
}
export function downloadresearchpdfreport(params) {
  // 下载教研报告PDF文档
  return request({
    url: `/report/statresearchreport/downloadresearchpdfreport`,
    method: "get",
    params: params,
  });
}
export function getresearchreportscoredistribution(params) {
  // 获取教研报告成绩分布
  return request({
    url: `/report/statresearchreport/getresearchreportscoredistribution`,
    method: "post",
    data: params,
  });
}
export function getresearchreportpaperanalypaperquality(params) {
  // 获取教研报告试卷分析 - 试卷命题质量
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalypaperquality`,
    method: "get",
    params: params,
  });
}
export function getresearchreportpaperanalyquestionquality(params) {
  // 获取教研报告试卷分析 - 试题命题质量
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestionquality`,
    method: "get",
    params: params,
  });
}
export function getresearchreportpaperanalyquestiondiagknowledgeoverall(
  params
) {
  // 获取教研报告试卷分析-试题诊断-知识点-各知识点得分率
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagknowledgeoverall`,
    method: "get",
    params: params,
  });
}
export function getresearchreportpaperanalyquestiondiagquestionoverall(params) {
  // 获取教研报告试卷分析-试题诊断-题目-各小题得分率
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagquestionoverall`,
    method: "get",
    params: params,
  });
}
export function getpaperquestionlist(params) {
  // 获取单科的试题列表
  if (params.viewSchoolId) {
    return request({
      url: `/report/statunionresearchreport/getpaperquestionlist`,
      method: "get",
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  } else {
    return request({
      url: `/report/statresearchreport/getpaperquestionlist`,
      method: "get",
      params: params,
    });
  }
}
export function getpaperknowledgelist(params) {
  // 获取单科的知识点列表
  if (params.viewSchoolId) {
    return request({
      url: `/report/statunionresearchreport/getpaperknowledgelist`,
      method: "get",
      params: params,
      headers: {
        "VIEW-SCHOOL-ID": params.viewSchoolId,
      },
    });
  } else {
    return request({
      url: `/report/statresearchreport/getpaperknowledgelist`,
      method: "get",
      params: params,
    });
  }
}
export function getresearchreportpaperanalyquestiondiagknowledgesingle(params) {
  // 获取教研报告试卷分析-试题诊断-题目-单个知识点情况
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagknowledgesingle`,
    method: "post",
    data: params,
  });
}
export function getresearchreportpaperanalyquestiondiagquestionsingle(params) {
  // 获取教研报告试卷分析-试题诊断-题目-单道试题情况
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagquestionsingle`,
    method: "get",
    params: params,
  });
}
export function getresearchreportpaperanalyquestiondiagknowledgescorerate(
  params
) {
  // 获取教研报告试卷分析-试题诊断-知识点-各班各知识点得分率情况
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagknowledgescorerate`,
    method: "get",
    params: params,
  });
}
export function getresearchreportpaperanalyquestiondiagquestionscorerate(
  params
) {
  // 教研报告-试卷分析-试题诊断-题目-各班各题得分率情况
  return request({
    url: `/report/statresearchreport/getresearchreportpaperanalyquestiondiagquestionscorerate`,
    method: "get",
    params: params,
  });
}
export function getresearchreportclasscompetitionscoredistribution(params) {
  // 教研报告-班级竞争力-班级成绩分布
  return request({
    url: `/report/statresearchreport/getresearchreportclasscompetitionscoredistribution`,
    method: "post",
    data: params,
  });
}
export function getresearchreportclasscompetitionindicator(params) {
  // 教研报告-班级竞争力-班级指标对比
  return request({
    url: `/report/statresearchreport/getresearchreportclasscompetitionindicator`,
    method: "get",
    params: params,
  });
}
export function getresearchreportsubjectcontribution(params) {
  // 教研报告-学科贡献
  return request({
    url: `/report/statresearchreport/getresearchreportsubjectcontribution`,
    method: "get",
    params: params,
  });
}
export function getresearchreportfocusonstudentcritical(params) {
  // 教研报告-重点关注学生-临界生
  return request({
    url: `/report/statresearchreport/getresearchreportfocusonstudentcritical`,
    method: "post",
    data: params,
  });
}
export function getresearchreportfocusonstudenttoporbackward(params) {
  // 教研报告-重点关注学生-拔尖生-后进生
  return request({
    url: `/report/statresearchreport/getresearchreportfocusonstudenttoporbackward`,
    method: "get",
    params: params,
  });
}
export function getresearchreportfocusonstudenttrack(params) {
  // 教研报告-重点关注学生-跟踪生
  return request({
    url: `/report/statresearchreport/getresearchreportfocusonstudenttrack`,
    method: "get",
    params: params,
  });
}
export function getresearchreportfocusonstudentwave(params) {
  // 教研报告-重点关注学生-波动生
  return request({
    url: `/report/statresearchreport/getresearchreportfocusonstudentwave`,
    method: "post",
    data: params,
  });
}
export function getresearchreportdevelopanalysis(params) {
  // 教研报告-发展性分析
  return request({
    url: `/report/statresearchreport/getresearchreportdevelopanalysis/v2`,
    method: "get",
    params: params,
  });
}
export function exportTeachingReport(params) {
  // 教研报告-导出
  return request({
    url: `/report/statresearchreport/export`,
    method: "post",
    data: params,
  });
}
export function exportTeachingReportNew(params) {
  // 教研报告-新高考-导出
  return request({
    url: `/report/statnewgaokaoresearchreport/export`,
    method: "post",
    data: params,
  });
}
