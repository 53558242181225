<template>
  <div class="diagnosis-point">
    <div class="tip">{{ overview }}</div>
    <chart
      v-if="questionSeries.length"
      :key="questionkey"
      style="margin-top: 18px"
      :define-options="questionOptions"
      :series="questionSeries"
      :data-zoom="20"
      :height="'500px'"
    />
    <no-data v-else></no-data>
    <div class="questions">
      <div class="label">选择知识点：</div>
      <div class="tab">
        <a-tabs v-model="value" :tab-position="'top'" @change="tabClick">
          <a-tab-pane
            v-for="item in paperQuestionList"
            :key="item.id"
            :tab="item.name"
          >
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="box">
      <exTable
        v-if="tableData.length"
        :key="'tableData' + singleKey"
        :columns="columns"
        style="margin-bottom: 18px"
        :data-source="tableData"
        :scroll="{ x: false }"
      />
      <chart
        v-if="singleSeries.length"
        :key="singleKey"
        :define-options="singleOptions"
        :height="'500px'"
        :series="singleSeries"
        :data-zoom="20"
      />
      <div class="checkNum" style="margin-bottom: 18px">
        选择班级：
        <check-component
          :source="classNumsList"
          :placeholder="'选择班级'"
          @change="getCheckClassNum"
        />
      </div>
      <exTable
        v-if="ratioTableData.length"
        :key="tableKey"
        :columns="ratioColumns"
        style="margin-bottom: 18px"
        :data-source="ratioTableData"
        :scroll="{ x: ratioScroll }"
      >
      </exTable>
      <div class="tip">注：标红的得分率表示班级得分率低于我校得分率</div>
    </div>
  </div>
</template>

<script>
import {
  getresearchreportpaperanalyquestiondiagknowledgeoverall,
  getpaperknowledgelist,
  getresearchreportpaperanalyquestiondiagknowledgesingle,
  getresearchreportpaperanalyquestiondiagknowledgescorerate,
} from "@/core/api/academic/teachingReport";
import chart from "../../../../components/charts.vue";
import { getstatclassoption } from "@/core/api/academic/common";
import checkComponent from "@/views/academic/teachingReport/components/checkComponent.vue";
import exTable from "../../../../components/exTable.vue";
export default {
  name: "DiagnosisPoint",
  components: {
    chart,
    exTable,
    checkComponent,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
    topData: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      questionOptions: {},
      questionSeries: [],
      overview: null,
      questionkey: null,
      paperQuestionList: [],
      value: null,
      progressData: [],
      answer: null,
      columns: [],
      columnsCols: [
        {
          title: "知识点",
          dataIndex: "name",
          align: "center",
        },
        {
          title: "数量",
          dataIndex: "countQuestion",
          align: "center",
        },
        {
          title: "满分分值",
          dataIndex: "score",
          align: "center",
        },
        {
          title: "平均分",
          dataIndex: "averageScore",
          align: "center",
        },
        {
          title: "校得分率",
          dataIndex: "schoolScoreRate",
          align: "center",
        },
        {
          title: "满分人数",
          dataIndex: "countRight",
          align: "center",
        },
        {
          title: "零分人数",
          dataIndex: "countZero",
          align: "center",
        },
        {
          title: "对应题号",
          dataIndex: "questionOrders",
          align: "center",
          width: 350,
        },
      ],
      tableData: [],
      singleOptions: {},
      singleSeries: [],
      singleKey: null,
      ratioTableData: [],
      classNumsList: [],
      classNumChecked: [],
      ratioColumns: [],
      ratioColumnsCols: [
        {
          dataIndex: "name",
          title: "题号",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "schoolScoreRate",
          title: "校得分率",
          align: "center",
          width: 120,
        },
        {
          title: "班级得分率",
          children: [],
          align: "center",
        },
      ],
      classTableLabel: [],
      tableKey: null,
      ratioScroll: false,
    };
  },
  mounted() {
    this.getKnowlegeRatio();
    this.getClassList();
  },
  methods: {
    getCheckClassNum(val) {
      this.classNumChecked = val.map((item) => item.name);
      this.getClassNumsPointData();
    },
    async getKnowlegeRatio() {
      this.questionSeries.length = 0;
      const res = await getresearchreportpaperanalyquestiondiagknowledgeoverall(
        {
          subjectId: this.subjectId,
          id: this.$route.query.id,
        }
      );
      this.overview = res.data.data.overview;
      this.getTabsData();
      let data =
        res.data.data
          .researchReportPaperAnalyQuestionDiagKnowledgeOverallKnowledgeVOs;
      if (data.length) {
        this.questionOptions = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          legend: { itemGap: 30 },
          grid: {
            bottom: data.length > 25 ? "20%" : "5%",
            containLabel: true,
          },
          dataZoom: [
            //滚动条
            {
              show: data.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: data.length - (data.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: data.map((item) => item.name),
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
                rotate: "45",
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          color: ["#619AF1", "#FF9776", "#6EDBA7", "#FFD766"],
        };
        this.questionSeries = [
          {
            name: "得分率", // 不同条柱
            type: "bar",
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: data.map((item) => {
              if (item.isLow === 1) {
                return {
                  value: item.scoreRate || "0",
                  itemStyle: { color: "#F56C6B" },
                };
              }
              return item.scoreRate || "0";
            }),
            barMaxWidth: 40,
          },
        ];
        this.questionkey = Math.random();
      }
    },
    async getTabsData() {
      const res = await getpaperknowledgelist({
        id: this.$route.query.id,
        subjectId: this.subjectId,
      });
      this.paperQuestionList = res.data.data;
      if (res.data.data.length) {
        this.value = res.data.data[0].id;
        this.getSinglePointData();
      }
    },
    async getSinglePointData() {
      this.singleOptions = {};
      this.singleSeries = [];
      const res = await getresearchreportpaperanalyquestiondiagknowledgesingle({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        knowledgeId: this.value,
      });
      let data = res.data.data;
      let cols = [this.columnsCols];
      if (this.$route.query.examType == 1) {
        cols.splice(4, 0, {
          dataIndex: "unionScoreRate",
          align: "center",
          title: "整体得分率",
        });
      }
      this.columns = [...cols];
      this.tableData = [
        {
          ...data.researchReportPaperAnalyQuestionDiagKnowledgeSingleStatVO,
          schoolScoreRate: data.schoolScoreRate,
        },
      ];
      if (data.researchReportPaperAnalyQuestionDiagKnowledgeSingleClassVOs) {
        let classVo =
          data.researchReportPaperAnalyQuestionDiagKnowledgeSingleClassVOs;
        this.singleOptions = {
          tooltip: {
            trigger: "axis",
            backgroundColor: "rgba(0,0,0,0.7)",
            borderColor: "rgba(0,0,0,0.7)",
            textStyle: {
              color: "#fff",
            },
            axisPointer: {
              type: "shadow",
              shadowStyle: {
                color: "rgba(0, 0, 0, 0.1)",
              },
            },

            valueFormatter: function (val) {
              return Math.abs(val);
            },
          },
          legend: { itemGap: 30 },
          grid: {
            bottom: classVo.length > 25 ? "20%" : "5%",
            containLabel: true,
          },
          dataZoom: [
            //滚动条
            {
              show: classVo.length > 25,
              type: "slider",
              realtime: true,
              startValue: 0, // 重点
              // 重点-dataX.length表示x轴数据长度
              endValue: classVo.length - (classVo.length - 25),
              xAxisIndex: [0],
              bottom: "5%",
              left: "center",
            },
          ],
          xAxis: [
            {
              type: "category",
              data: classVo.map((item) => item.classNum),
              axisTick: {
                show: false,
              },
              axisLabel: {
                show: true,

                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
                formatter: (val) => {
                  let arr = val.split("");
                  let index = 0;
                  let newArray = [];
                  while (index < arr.length) {
                    let data = arr.slice(index, (index += 7));
                    newArray.push(data.join(""));
                  }
                  let str = newArray.reduce(
                    (str, item) => str + "\n" + item,
                    ""
                  );
                  return str;
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          color: ["#619AF1", "#FF9776", "#6EDBA7", "#FFD766"],
        };
        this.singleSeries = [
          {
            name: "班级得分率", // 不同条柱
            type: "bar",
            label: {
              position: "top",
              show: true,
              color: "inherit",
              fontSize: 9,
            },
            data: classVo.map((item) => {
              if (item.isLow == 1) {
                return {
                  value: item.classScoreRate || "0",
                  itemStyle: { color: "#F56C6B" },
                };
              }
              return item.classScoreRate || "0";
            }),
            barMaxWidth: 40,
          },
          {
            name: "学校得分率",
            type: "line",
            color: "#FFA486",
            data: classVo.map(() => res.data.data.schoolScoreRate || 0),
          },
        ];
        if (this.$route.query.examType == 1) {
          this.singleSeries.push({
            name: "整体得分率",
            type: "line",
            symbol: "none",
            smooth: true,
          });
        }
        this.singleKey = Math.random();
      }
    },
    async getClassList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        requestPage: 2,
      });
      this.classNumsList = res.data.data;
      this.classNumChecked = res.data.data.map((item) => item.name);
      this.getClassNumsPointData();
    },
    async getClassNumsPointData() {
      let cols = [...this.ratioColumnsCols];
      let colNum = 2;
      if (this.$route.query.examType == 1) {
        colNum = 3;

        cols.splice(1, 0, {
          dataIndex: "unionScoreRate",
          align: "center",
          title: "整体得分率",
        });
      }
      this.ratioColumns = [...cols];
      const res =
        await getresearchreportpaperanalyquestiondiagknowledgescorerate({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          classNums: this.classNumChecked.toString(),
        });
      this.ratioTableData =
        res.data.data.researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeVOs;
      let data =
        res.data.data
          .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeVOs;
      if (data.length) {
        this.classTableLabel =
          data[0].researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs;
      }
      if (this.classTableLabel.length) {
        this.ratioScroll = true;
        // this.ratioColumns[0].fixed = "left";
        this.ratioColumns[colNum] = {
          title: "班级得分率",
          align: "center",
          children: [
            ...this.classTableLabel.map((item, index) => {
              return {
                dataIndex: "classScoreRate" + index,
                title: item.classNum,
                align: "center",
                width: 100,
                customRender: (text, record) => {
                  let classScoreRate =
                    record
                      .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs[
                      index
                    ].classScoreRate;
                  return <span>{classScoreRate}</span>;
                },
                customCell: (record) => {
                  let data =
                    record
                      .researchReportPaperAnalyQuestionDiagKnowledgeScoreRateKnowledgeClassVOs[
                      index
                    ].classScoreRate;
                  if (record.schoolScoreRate >= data) {
                    return {
                      style: {
                        color: "#fd8585",
                      },
                    };
                  }
                },
              };
            }),
          ],
        };
        this.$forceUpdate();
        this.tableKey = Math.random();
      } else {
        this.ratioScroll = false;
        this.ratioColumns.pop();
        this.ratioColumns.forEach((item) => {
          this.$set(item, "width", "auto");
        });
        this.tableKey = Math.random();
      }
    },
    tabClick(val) {
      this.value = val;
      this.getSinglePointData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../new-exam.module.scss";

.diagnosis-point {
  .questions {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .label {
      white-space: nowrap;
      width: 120px;
    }
    .tab {
      width: calc(100% - 120px);
    }
  }
}
.box {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 18px;
  padding: 18px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
}
::v-deep .ant-tabs-bar {
  margin: 0 0 0 0;
}
</style>
