import request from "@/core/services/axios";
export const getTopData = (params) => {
  // 获取新高考教研报告-顶部数据
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreporttopinfo`,
    method: "GET",
    params: params,
  });
};
export const getBrach = (params) => {
  // 获取新高考教研报告-成绩分布
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportscoredistribution`,
    method: "post",
    data: params,
  });
};
export const getresearchreportclasscompetitionindicator = (params) => {
  // 获取新高考教研报告-班级竞争力-班级指标对比
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportclasscompetitionindicator`,
    method: "get",
    params: params,
  });
};
export const classscoredistribution = (params) => {
  // 获取新高考教研报告-班级竞争力-班级成绩分布
  return request({
    url: `/report/statnewgaokaoschoolreport/getschoolreportclasscompetitive/classscoredistribution`,
    method: "post",
    data: params,
  });
};
export const waveStudent = (params) => {
  // 获取新高考教研报告-重点关注学生-波动生
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportfocusonstudentwave`,
    method: "post",
    data: params,
  });
};
export const topOrBackward = (params) => {
  // 校教研报告--重点关注学生-后进生
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportfocusonstudenttoporbackward`,
    method: "get",
    params: params,
  });
};
export const trackStudent = (params) => {
  // 教研报告--重点关注学生-跟踪生
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportfocusonstudenttrack`,
    method: "get",
    params: params,
  });
};
export const getCritical = (params) => {
  // 教研报告--重点关注学生-选科临界生
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportfocusonstudentcritical`,
    method: "get",
    params: params,
  });
};
export const getDevelopMent = (params) => {
  // 教研报告--发展性分析
  return request({
    url: `/report/statnewgaokaoresearchreport/getresearchreportdevelopanalysis`,
    method: "get",
    params: params,
  });
};
