export const getClassType = (subjectId) => {
  if ([0, 1, 2, 3].includes(subjectId)) {
    return "行政";
  } else {
    return "教学";
  }
};
/**
 * @constructor - 通过topData中的参数控制模块显示隐藏
 * @param {String} key - 判断隐藏显示的参数名
 */
export function unionVisibleByParams(key) {
  if (this.$route.query.examType == 1) {
    return this.topData[key] == 1;
  }
  return true;
}

/**
 * @constructor-通过联考学生成绩发布字段 payload 控制数据显示
 * @param {Number} payload - 0.不显示 1.显示联考得分率（含学校、班级得分率） 2.显示学校得分率（含班级得分率） 3.显示班级得分率
 * @typedef {Object} column
 * @property {String} title - 表头名称
 * @param {Object[]} columns
 */
export function columnbyParams(payload = 0, columns) {
  // let { roleId } = JSON.parse(localStorage.getItem("jzjx-userInfo")).content;
  // // roleId 4 学生  5 老师
  // if (roleId == 5) {
  //   return columns;
  // }
  // 2024/9/14 后台控制住了数据 所以全部角色控制
  if (payload == 0) {
    return [];
  } else if (payload == 1) {
    return columns;
  } else if (payload == 2) {
    return columns.filter((item) => item.title.indexOf("整体") == -1);
  } else if (payload == 3) {
    return columns.filter((item) => item.title.indexOf("班级") > -1);
  }
}
