<template>
  <div class="quality">
    <chart
      :key="chartKey"
      :define-options="options"
      :series="series"
      :height="'500px'"
    />
    <div class="tip" style="margin-bottom: 18px">
      散点图用以说明各道小题的质量情况，并按照12象限显示。横坐标是试题难度，虚线将题目按照难度分割为3个区域：难题[0,0.4]，中等题(0.4,0.7)，简单题[0.7,1]；纵坐标是试题区分度，虚线将题目按照区分度分割为4个区域：区分度低[~,0.2)，区分度一般[0.2,0.3)，区分度良好[0.3,0.4)，区分度优秀[0.4,~)。坐标轴中每一个点代表一道题（难度，区分度）。
    </div>
  </div>
</template>

<script>
import chart from "../../../../components/charts.vue";
import { getresearchreportpaperanalyquestionquality } from "@/core/api/academic/teachingReport";
import { unionGetresearchreportpaperanalyquestionquality } from "@/core/api/newExamAcademic/union/teaching.js";
export default {
  name: "Quality",
  components: {
    chart,
  },
  data() {
    return {
      options: {},
      series: [],
      chartKey: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    async getData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionGetresearchreportpaperanalyquestionquality({
          id: this.$route.query.id,
          subjectId: this.$parent.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getresearchreportpaperanalyquestionquality({
          id: this.$route.query.id,
          subjectId: this.$parent.subjectId,
        });
      }

      this.options = {
        tooltip: {
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },
        },
        grid: {
          bottom: "10%",
          left: "10%",
        },
        xAxis: {
          name: "难度",
          nameLocation: "middle",
          nameTextStyle: {
            padding: [10, 0, 0, 0], // 四个数字分别为上右下左与原位置距离
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          max: 1,
          min: 0,
        },
        yAxis: {
          axisLine: {
            show: true,
            lineStyle: {
              color: "rgba(0,0,0,0.45)",
            },
          },
          name: "区分度",
          nameLocation: "middle",
          nameTextStyle: {
            padding: [0, 10, 0, 0], // 四个数字分别为上右下左与原位置距离
          },
          splitLine: {
            show: false,
          },
          axisTick: {
            show: false,
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "rgba(0, 0, 0, 0.45)",
            },
          },
          max: 1,
          // min: -1,// 2024/5/15 宜宾一中 高一下期半期联合考试  区分度有为负数情况，
          min: 0,
        },
        toolbox: {
          feature: {
            dataZoom: {},
          },
        },
      };
      this.series = [
        {
          symbolSize: 12,
          name: "质量情况",
          data: res.data.data.map((item) => {
            return [item.scoreRate, item.discrimination, item.order];
          }),
          type: "scatter",
          color: ["#5B8FF9"],
          label: {
            show: true,
            textStyle: {
              color: "#5B8FF9",
            },
            position: "right",
            formatter: function (params) {
              return params.data[2];
            },
          },
          markLine: {
            symbol: ["circle", "arrow"],
            data: [
              {
                silent: false, //鼠标悬停事件  true没有，false有
                name: "一般(0.2,0.3)",
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "#AB98FB ",
                  width: "2",
                },
                label: {
                  position: "middle",
                  formatter: "一般(0.2,0.3)",
                  color: "#AB98FB",
                },
                yAxis: 0.2, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              },
              {
                silent: false, //鼠标悬停事件  true没有，false有
                name: "良好(0.3,0.4)",
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "#F09710",
                  width: "2",
                },
                label: {
                  position: "middle",
                  formatter: "良好(0.3,0.4)",
                  color: "#F09710",
                },
                yAxis: 0.3, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              },
              {
                silent: false, //鼠标悬停事件  true没有，false有
                name: "优秀(0.4,1)",
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "#6EDBA7",
                  width: "2",
                },
                label: {
                  position: "middle",
                  formatter: "优秀(0.4,1)",
                  color: "#6EDBA7",
                },
                yAxis: 0.4, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              },
              {
                silent: false, //鼠标悬停事件  true没有，false有
                name: "中等题(0.4,0.7)",
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "#FF9474 ",
                  width: "2",
                },
                label: {
                  position: "end",
                  formatter: "中等题(0.4,0.7)",
                  color: "#FF9474 ",
                },
                xAxis: 0.4, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              },
              {
                silent: false, //鼠标悬停事件  true没有，false有
                name: "简单题(0.7,1)",
                lineStyle: {
                  //警戒线的样式  ，虚实  颜色
                  type: "dashed",
                  color: "#63A8EC",
                  width: "2",
                },
                label: {
                  position: "end",
                  formatter: "简单题(0.7,1)",
                  color: "#63A8EC",
                },
                xAxis: 0.7, // 警戒线的标注值，可以有多个yAxis,多条警示线   或者采用   {type : 'average', name: '平均值'}，type值有  max  min  average，分为最大，最小，平均值
              },
            ],
          },
        },
      ];
      this.chartKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../new-exam.module.scss";

.quality {
}
</style>
