<template>
  <div ref="brach" v-lazyChart="{ fn: typeChange }" class="container">
    <div class="title">成绩分布</div>
    <div class="sub-title">成绩分布</div>
    <div class="operate">
      数据源：
      <a-radio-group v-model="dataSource" @change="onChange">
        <a-radio :value="1"> 原始分</a-radio>
        <a-radio v-if="![1, 2, 3, 4, 8].includes(subjectId)" :value="2">
          赋分
        </a-radio>
      </a-radio-group>

      <div class="scoreSegmentation">
        分数段区间：
        <span>上限：</span>
        <a-input v-model="search.high" style="width: 64px" />
        <span>分</span>
        <span>下限：</span>
        <a-input v-model="search.low" style="width: 64px" />
        <span>分</span>
        <span>间隔：</span>
        <a-input v-model="search.interval" style="width: 64px" />
        <span>分</span>
        <a-button type="primary" @click="getBrachData">确定</a-button>
      </div>
    </div>
    <div class="switch">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button :value="1"> 人数 </a-radio-button>
        <a-radio-button :value="2"> 占比 </a-radio-button>
      </a-radio-group>
    </div>
    <div class="charts">
      <chart
        :key="tokenKey"
        :series="chartSeries"
        :define-options="defineOptions"
        :data-zoom="20"
      />
    </div>
    <div class="tip">
      <div class="intent">注：</div>
      <div class="text">
        <div>
          除{{
            dataSource == 1 ? "原始分" : "赋分"
          }}均分外，以上数据结果均以原始分进行统计运算
        </div>
        <div>以上数据均不包括不参与统计的考生成绩</div>
      </div>
    </div>
  </div>
</template>

<script>
import chart from "../../../components/charts.vue";
import { getBrach } from "@/core/api/newExamAcademic/teaching";
import { getresearchreportscoredistribution } from "@/core/api/newExamAcademic/union/teaching";
export default {
  components: {
    chart,
  },
  props: {
    topData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      category: 1,
      type: 1,
      search: {
        high: null,
        interval: null,
        low: null,
      },
      dataSource: 1,
      chartSeries: [],
      tokenKey: Math.random(),
      subjectId: null,
      defineOptions: {},
      chartData: [],
    };
  },
  mounted() {
    this.subjectId = this.$parent.subjectId;
    if ([1, 2, 3, 4, 8].includes(this.subjectId)) {
      this.dataSource = 1;
    }
  },
  methods: {
    isNumber() {
      return (
        /^[0-9]*$/.test(this.search.high) &&
        /^[0-9]*$/.test(this.search.low) &&
        /^[0-9]*$/.test(this.search.interval)
      );
    },
    async getBrachData(loadingInstance, io) {
      const result = this.isNumber();
      if (!result) {
        this.$message({
          type: "warning",
          message: "请输入正整数",
          showClose: true,
        });
        return false;
      }
      try {
        let res;
        if (this.$route.query.schoolId) {
          res = await getresearchreportscoredistribution({
            id: this.$route.query.id,
            subjectId: this.subjectId,
            dataSource: this.dataSource,
            ...this.search,
            viewSchoolId: this.$route.query.schoolId,
          });
        } else {
          res = await getBrach({
            id: this.$route.query.id,
            subjectId: this.subjectId,
            dataSource: this.dataSource,
            ...this.search,
          });
        }

        this.chartData = res.data.data;
        if (this.type === 1) {
          this.defineOptions = {
            lenged: {},
            grid: {
              containLabel: true,
            },
            xAxis: {
              type: "category",
              axisTick: { show: false },
              data: [...this.chartData.map((item) => item.segment)],
              axisLabel: {
                show: true,
                rotate: "45",
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
            },
          };
          this.chartSeries = [
            {
              data: [
                ...this.chartData.map((item) => {
                  if (item["isGradeAverage"] == 1) {
                    return {
                      value: item.total,
                      itemStyle: {
                        color: "#F56C6B",
                      },
                    };
                  } else {
                    return item.total;
                  }
                }),
              ],
              type: "bar",
              name: "人数",
              barMaxWidth: 40,
              label: {
                position: "top",
                color: "inherit",
                show: true,
              },
            },
          ];
          this.tokenKey = Math.random();
        } else {
          this.defineOptions = {
            grid: {
              containLabel: true,
            },
            xAxis: {
              type: "category",
              axisTick: { show: false },
              data: [...this.chartData.map((item) => item.segment)],
              axisLabel: {
                show: true,
                rotate: "45",
                textStyle: {
                  color: "rgba(0, 0, 0, 0.45)",
                },
              },
            },
          };
          this.chartSeries = [
            {
              data: [
                ...this.chartData.map((item) => {
                  if (item["isGradeAverage"] == 1) {
                    return {
                      value: item.proportion,
                      itemStyle: {
                        color: "#F56C6B",
                      },
                    };
                  } else {
                    return item.proportion;
                  }
                }),
              ],
              type: "bar",
              barMaxWidth: 40,
              name: "占比",

              label: {
                position: "top",
                color: "inherit",
                show: true,
                formatter: ({ value }) => value + "%",
              },
            },
          ];
          if (
            this.$parent.topData.isSchoolDisplayReportUnionAverageScore == 1
          ) {
            this.chartSeries.push({
              name: "整体",
              color: "#F3987C",
              type: "line",
              data: this.chartData.map((item) => item.unionProportion),
            });
          }
          this.tokenKey = Math.random();
        }
        this.closeLoading(loadingInstance, io);
      } catch {
        this.closeLoading(loadingInstance, io);
      }
    },
    checkAll() {
      this.search.resEleccombId = null;
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.brach);
        });
      }
    },
    onChange() {
      this.getBrachData();
    },
    typeChange(loadingInstance, io) {
      this.search.high = this.topData.segmentMaximum;
      this.search.low = this.topData.segmentMinimum;
      this.search.interval = this.topData.segmentGap;
      this.getBrachData(loadingInstance, io);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 18px;
  .scoreSegmentation {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 40px;
    span {
      margin: 0 8px;
    }
  }
}
.switch {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  margin-bottom: 18px;
}
</style>
