<template>
  <div ref="student" v-lazyChart="{ fn: getCondition }" class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 3,
        subjectId: subjectId,
        ...wave,
        topOrBackward: topOrBackward,
      }"
    >
      <div class="title">重点关注学生</div>
    </exportBtn>
    <div class="operate">
      <a-radio-group v-model="type" button-style="solid" @change="typeChange">
        <a-radio-button
          v-if="isChoosenSubject && $route.query.examType != 1"
          :value="5"
        >
          选科临界生
        </a-radio-button>
        <a-radio-button v-if="showStudentRank()" :value="1">
          波动生
        </a-radio-button>
        <a-radio-button v-if="showStudentRank()" :value="2">
          拔尖生
        </a-radio-button>
        <a-radio-button v-if="showStudentRank()" :value="3">
          后进生
        </a-radio-button>
        <a-radio-button :value="4"> 跟踪生 </a-radio-button>
      </a-radio-group>
    </div>
    <div v-show="tableData.length">
      <div class="charts">
        <template v-if="type === 5">
          <div class="critical">
            <!-- <a-table
              :key="'critital' + chartKey"
              :columns="critialColumns"
              :scroll="{ x: 'max-content' }"
              style="margin-bottom: 18px"
              :data-source="crititalData"
              :pagination="false"
              bordered
            ></a-table> -->
            <a-table
              :columns="criticalCol"
              :data-source="tableData"
              :pagination="false"
              bordered
              style="flex: 1"
            >
            </a-table>
          </div>
        </template>
        <template v-if="type === 1">
          <div class="filter">
            <div>
              <span>年级排名前：</span>
              <a-input
                v-model="wave.upTop"
                oninput="value=value.replace(/[^\d]/g,'')"
                :max-length="5"
                style="width: 64px"
                @blur="wave.upTop = $event.target.value"
              ></a-input>
              <span>名中，与选中考试对比进步最大的前</span>
              <a-input
                v-model="wave.upMax"
                oninput="value=value.replace(/[^\d]/g,'')"
                style="width: 64px"
                :max-length="5"
                @blur="wave.upMax = $event.target.value"
              ></a-input>
              <span>名。</span>
            </div>
            <div>
              对比考试：
              <a-select
                v-model="wave.upStatId"
                not-found-content="暂无数据"
                style="width: 415px"
                @change="validateWave"
              >
                <a-select-option
                  v-for="(item, index) in selectOptions"
                  :key="'selectOptions' + index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-button type="primary" @click="validateWave">确定</a-button>
            </div>
          </div>
        </template>
        <div v-else-if="type === 2" class="filter">
          校排名前：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            style="width: 72px"
            :max-length="5"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="searchSubmit"
            >确定</a-button
          >
        </div>
        <div v-else-if="type === 3" class="filter">
          校排名后：<a-input
            v-model="topOrBackward"
            oninput="value=value.replace(/[^\d]/g,'')"
            :max-length="5"
            style="width: 72px"
            @blur="topOrBackward = $event.target.value"
          ></a-input>
          <a-button
            type="primary"
            style="margin-left: 10px"
            @click="searchSubmit"
            >确定</a-button
          >
        </div>
        <chart
          v-if="type !== 5"
          :key="'series1' + chartKey"
          :series="series1"
          :define-options="defineOptions"
        />
      </div>
      <template v-if="type === 1">
        <div class="charts">
          <div class="filter">
            <div>
              <span>年级排名前：</span>
              <a-input
                v-model="wave.downTop"
                oninput="value=value.replace(/[^\d]/g,'')"
                :max-length="5"
                style="width: 64px"
                @blur="wave.downTop = $event.target.value"
              ></a-input>
              <span>名中，与选中考试对比退步最大的前</span>
              <a-input
                v-model="wave.downMax"
                oninput="value=value.replace(/[^\d]/g,'')"
                :max-length="5"
                style="width: 64px"
                @blur="wave.downMax = $event.target.value"
              ></a-input>
              <span>名。</span>
            </div>
            <div>
              对比考试：
              <a-select
                v-model="wave.downStatId"
                style="width: 415px"
                not-found-content="暂无数据"
                @change="validateWave"
              >
                <a-select-option
                  v-for="(item, index) in selectOptions"
                  :key="'selectOptions' + index"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-button type="primary" @click="validateWave">确定</a-button>
            </div>
          </div>
          <chart
            :key="'series2' + chartKey"
            :series="series2"
            :define-options="defineOptions"
          />
        </div>
      </template>
    </div>
    <no-data v-show="!tableData.length" />
    <div v-if="![4, 5].includes(type)" class="classNum">
      选择班级：
      <classNums
        ref="classNums"
        :key="type"
        placeholder="选择班级"
        :class-num-list="classNumList"
        @change="classNumChange"
      />
    </div>

    <a-table
      v-if="type != 5"
      :key="'a-table' + chartKey"
      :columns="columns"
      :data-source="tableData"
      bordered
      :pagination="false"
      :row-key="(record, index) => index"
      :scroll="{ x: 'max-content' }"
    >
      <div slot="upStudentList" slot-scope="text, record">
        <a-button
          v-for="(item, index) in record.StudentList"
          :key="index"
          type="link"
          >{{ item.name }}
        </a-button>
      </div>
    </a-table>

    <div class="tip" style="margin-top: 18px">
      <div v-if="type === 1">
        <div>
          波动生：以某次考试为对比入口，检索出波动幅度较大的学生，分为上升波动（处于成绩提升趋势）及下降波动（处于成绩下降趋势）。对上升波动的学生给予肯定，激励其保持上升趋势；对下降波动学生，辅助分析原因并给予调整建议和鼓励。特别是排名靠前或临界状态的学生，可进一步查看学生报告了解历次发展情况，进行波动性深度分析。
        </div>
        <div>注：不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 2">
        <div>
          拔尖生（优生劣科）：了解拔尖生群体分布情况，以及优生劣科情况（拔尖生存在“严重偏科”的学科）。优生劣科可结合学生自身的学习特点分析，是否在本学科存在学有余力但方法不当或兴趣不高的情况，如存在应适当引导学生以总分为前提进行偏科学科的学习方法或学习状态的调整，同时也可提升班级总分高分群体占比。
        </div>
        <div>注：1.标橙学生为优生劣科学生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 3">
        <div>
          后进生（潜力学科）：了解后进生群体分布情况，以及潜力学科情况（后进生存在单科排名大幅度优于总分排名的学科）。后进生的潜力学科，可依据学生情况针对性分析，是否存在学有余力但学习动力不足的问题导致仅兴趣或天赋学科成绩较好，其他学科成绩滞后的情况。如存在应采取相应手段激发学生学习动力，并给予潜力学科肯定，如给予学生该兴趣学科的生涯规划指导等。
        </div>
        <div>注：1.标蓝学生为潜力生</div>
        <div>2.不参与统计的学生不参与此处计算，也不在此处显示</div>
      </div>
      <div v-if="type === 4">
        <div>
          跟踪生：仅当在报告设置中导入了跟踪生且跟踪生对应成功才显示。跟踪生（目标跟踪生）即为实现上线目标最稳定的学生群体，为目标基础保底儿。可从两方面设置
        </div>
        <div>
          一、（基于目标）结合学生入口成绩及（中）高考预期，确定学校跟踪生名单，各班数量不均。
        </div>
        <div>
          二、（基于管理）每班确定N名跟踪生，多为成绩稳定的尖子生，每班数量均匀。
        </div>
      </div>
      <div v-if="type === 5">
        <div>
          选科临界生：通过对再选科目（政治、地理、生物、化学）不同赋分档位的原始分进行排名后可划分为潜力生和预警生；潜力生为B档、C档、D档、E档的原始分排名在前五名的考生；预警生为A档、B档、C档、D档的原始分排名在后五名的考生。
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import chart from "../../../components/charts.vue";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import {
  waveStudent,
  topOrBackward,
  trackStudent,
  getCritical,
} from "@/core/api/newExamAcademic/teaching";
import classNums from "../../../components/classNums.vue";
import { exportTeachingReportNew } from "@/core/api/academic/teachingReport";
import {
  getresearchreportfocusonstudentwave,
  getresearchreportfocusonstudenttoporbackward,
  getresearchreportfocusonstudenttrack,
} from "@/core/api/newExamAcademic/union/teaching";
import {
  getstatclassoption,
  getstatcomparelist,
} from "@/core/api/academic/common";
import { getSubjectList } from "@/core/util/util";
export default {
  components: {
    chart,
    classNums,
    exportBtn,
  },
  props: {
    subjectId: {
      type: [String, Number],
      default: "",
    },
  },
  data() {
    let subjectList = getSubjectList();
    return {
      requestFunc: exportTeachingReportNew,
      type: 5,
      wave: {
        downMax: 20,
        downTop: 100,
        upMax: 20,
        upTop: 100,
        downStatId: "",
        upStatId: "",
      },
      critialColumns: [],
      crititalData: [],
      selectOptions: [],
      series1: [],
      series2: [],
      defineOptions: {},
      tableData: [],
      columns: [],
      classNum: null,
      classNumList: [],
      topOrBackward: 20,
      checkClassNum: [],
      chartKey: Math.random(),
      waveCol: [
        // 波动生
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "statTotal",
          title: "统计考生",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "upTop",
          title: "上升人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "upProportion",
          title: "占比",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "upStudents",
          title: "学生名单",
          align: "center",
          customRender: (text, record) => {
            return (
              <div style="text-align:left">
                {record.upStudents.map((item) => (
                  <a-button
                    type="link"
                    vOn:click={() => this.handleClick(record, item)}
                  >
                    {item.name}
                  </a-button>
                ))}
              </div>
            );
          },
          customCell: () => {
            return {
              style: {
                "min-width": "100px",
              },
            };
          },
        },
        {
          dataIndex: "downTop",
          title: "下降人数",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "downProportion",
          title: "占比",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "downStudents",
          title: "学生名单",
          align: "center",
          customRender: (text, record) => {
            return (
              <div style="text-align:left">
                {record.downStudents.map((item) => (
                  <a-button
                    type="link"
                    vOn:click={() => this.handleClick(record, item)}
                  >
                    {item.name}
                  </a-button>
                ))}
              </div>
            );
          },
          customCell: () => {
            return {
              style: {
                "min-width": "100px",
              },
            };
          },
        },
      ],
      topCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 75,
        },
        {
          dataIndex: "teacherName",
          title: "班主任",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          width: 100,
          align: "center",
        },
        {
          title: "拔尖生",
          align: "center",
          children: [
            {
              dataIndex: "total",
              title: "人数",
              width: 75,
              align: "center",
              customRender: (text, record) => record.total,
            },
            {
              dataIndex: "proportion",
              title: "占比",
              width: 75,
              align: "center",
              customRender: (text, record) => record.proportion,
            },
            {
              title: "学生名单",
              align: "center",
              customRender: (text, record) => {
                if (record.students) {
                  return (
                    <div style="text-align:left">
                      {record.students.map((item) => (
                        <a-button
                          type="link"
                          vOn:click={() => this.handleClick(record, item)}
                        >
                          {item.name}
                        </a-button>
                      ))}
                    </div>
                  );
                } else {
                  return "";
                }
              },
            },
          ],
        },
      ],
      backCol: [
        {
          dataIndex: "classNum",
          title: "班级",
          width: 75,
          align: "center",
        },
        {
          dataIndex: "teacherName",
          width: 85,
          title: "班主任",
          align: "center",
        },
        {
          dataIndex: "statTotal",
          title: "统计人数",
          width: 100,
          align: "center",
        },
        {
          title: "后进生",
          align: "center",
          children: [
            {
              dataIndex: "total",
              title: "人数",
              width: 75,
              align: "center",
              customRender: (text, record) => record.total,
            },
            {
              dataIndex: "proportion",
              title: "占比",
              width: 75,
              align: "center",
              customRender: (text, record) => record.proportion,
            },
            {
              title: "学生名单",
              align: "center",
              customRender: (text, record) => {
                if (record.students) {
                  return (
                    <div style="text-align:left">
                      {record.students.map((item) => (
                        <a-button
                          type="link"
                          vOn:click={() => this.handleClick(record, item)}
                        >
                          {item.name}
                        </a-button>
                      ))}
                    </div>
                  );
                } else {
                  return "";
                }
              },
            },
          ],
        },
      ],
      trackCol: [
        {
          dataIndex: "name",
          title: "姓名",
          align: "center",
          width: 85,
        },
        {
          dataIndex: "subjectTeacherName",
          title: "任课老师",
          align: "center",
          width: 100,
        },

        {
          dataIndex: "subjectRankOri",
          title: "学科排名",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "subjectScoreOri",
          title: "学科总分",
          align: "center",
          width: 100,
        },
        {
          dataIndex: "classNum",
          title: "班级",
          align: "center",
          width: 85,
        },
      ],
      subjectList: [...subjectList],
      isChoosenSubject: false,
      criticalCol: [
        {
          title: "等级",
          align: "center",
          widht: 100,
          dataIndex: "level1",
          customRender: (text, record) => record.level,
        },
        {
          title: "风险生(后五名）名单",
          dataIndex: "riskStudents",
          customRender: (text, record) => {
            let data =
              record.extend || record.riskStudents.length <= 5
                ? record.riskStudents
                : record.riskStudents.slice(0, 5);
            let button;
            if (record.riskStudents.length > 5) {
              button = record.extend ? (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extend", false)}
                >
                  收起
                  <a-icon type="up" style="font-size:12px" />
                </a-button>
              ) : (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extend", true)}
                >
                  查看更多
                  <a-icon type="down" style="font-size:12px" />
                </a-button>
              );
            }

            return (
              <div class="name-box">
                <div class="nameList">
                  {...data.map((item) => (
                    <div class="name">
                      <a-button
                        type="link"
                        vOn:click={() => this.handleClick(record, item)}
                      >
                        {item.name}
                      </a-button>
                    </div>
                  ))}
                </div>
                <div> {button}</div>
              </div>
            );
          },
        },
        {
          title: "等级",
          align: "center",
          widht: 100,
          dataIndex: "level2",
          customRender: (text, record) => record.level,
        },
        {
          title: "潜力生(前五名）名单",
          dataIndex: "potentialStudents",
          customRender: (text, record) => {
            let data =
              record.extendPo || record.potentialStudents.length <= 5
                ? record.potentialStudents
                : record.potentialStudents.slice(0, 5);
            let button;
            if (record.potentialStudents.length > 5) {
              button = record.extendPo ? (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extendPo", false)}
                >
                  收起 <a-icon type="up" style="font-size:12px" />
                </a-button>
              ) : (
                <a-button
                  type="link"
                  vOn:click={() => this.$set(record, "extendPo", true)}
                >
                  查看更多 <a-icon type="down" style="font-size:12px" />
                </a-button>
              );
            }

            return (
              <div class="name-box">
                <div class="nameList">
                  {...data.map((item) => (
                    <div class="name">
                      <a-button
                        type="link"
                        vOn:click={() => this.handleClick(record, item)}
                      >
                        {item.name}
                      </a-button>
                    </div>
                  ))}
                </div>
                <div>{button}</div>
              </div>
            );
          },
        },
      ],
      classNumKey: null,
    };
  },
  mounted() {
    // this.typeChange();

    if ([0, 1, 2, 3, 4, 8].includes(this.subjectId)) {
      this.isChoosenSubject = false;
      this.type = 1;
    } else {
      this.isChoosenSubject = true;
      this.type = 5;
    }
  },
  methods: {
    showStudentRank() {
      if (this.$route.query.examType == 1) {
        return this.$parent.topData.isSchoolDisplayReportStudentRank == 1;
      }
      return true;
    },
    handleClick(record, item) {
      const { href } = this.$router.resolve({
        path: "/academic/new-exam/student-report",
        query: {
          ...this.$route.query,
          studentName: item.name,
          studentId: item.studentId,
          subjectId: this.subjectId,
          classNum: record.classNum,
          level: this.$route.query.level,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },

    async getCondition(loadingInstance, io) {
      const classRes = await getstatclassoption({
        id: this.$route.query.id,
        requestPage: 1,
        subjectId: this.subjectId,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumList = classRes.data.data.map((item) => item.name);
      this.checkClassNum = classRes.data.data.map((item) => item.name);
      this.classNumKey = new Date().getTime();
      if (this.$route.query.examType == 1) {
        if (this.showStudentRank()) {
          this.type = 1;
          if ([0, 1, 2, 3, 4, 8].includes(this.subjectId)) {
            this.columns = [...this.waveCol];
          }
        } else {
          this.type = 4;
        }
      } else {
        this.type = 5;
      }

      this.typeChange();
      this.closeLoading(loadingInstance, io);
    },
    async getWaveData() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getresearchreportfocusonstudentwave({
          classNums: this.checkClassNum.toString(),
          ...this.wave,
          statId: Number(this.$route.query.id),
          isAllClass: 0,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await waveStudent({
          classNums: this.checkClassNum.toString(),
          ...this.wave,
          statId: this.$route.query.id,
          isAllClass: 0,
          subjectId: this.subjectId,
        });
      }

      this.tableData = res.data.data;
      this.chartKey = Math.random();
      this.defineOptions = {
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: [...this.tableData.map((item) => item.classNum)],
        },
      };
      this.series1 = [
        {
          type: "bar",
          name: "波动生",
          data: [...this.tableData.map((item) => item.upTotal)],
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ];
      this.series2 = [
        {
          type: "bar",
          name: "波动生",
          data: [...this.tableData.map((item) => item.downTotal)],
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ];
    },
    async getTopOrBackward() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getresearchreportfocusonstudenttoporbackward({
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          classNums: this.checkClassNum.toString(),
          subjectId: this.subjectId,
          isAllClass: 0,
          id: this.$route.query.id,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await topOrBackward({
          topOrBackward: this.topOrBackward,
          type: this.type == 2 ? 1 : 2,
          classNums: this.checkClassNum.toString(),
          subjectId: this.subjectId,
          isAllClass: 0,
          id: this.$route.query.id,
        });
      }

      this.tableData = res.data.data;
      this.defineOptions = {
        xAxis: {
          axisTick: {
            show: false,
          },
          type: "category",
          data: [...this.tableData.map((item) => item.classNum)],
        },
      };
      this.series1 = [
        {
          type: "bar",
          name: this.type == 2 ? "拔尖生" : "后进生",
          data: [...this.tableData.map((item) => item.total)],
          barMaxWidth: 40,
          label: {
            show: true,
            position: "top",
            color: "inherit",
          },
        },
      ];
      this.chartKey = Math.random();
    },
    async trackStudent() {
      let res;
      if (this.$route.query.examType == 1) {
        res = await getresearchreportfocusonstudenttrack({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await trackStudent({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      if (res.data.data.studentVOs) {
        this.columns = [
          ...this.trackCol,
          {
            dataIndex: "weak",
            title: "学科薄弱程度",
            align: "center",
            width: 150,
          },
        ];
        this.tableData = res.data.data.studentVOs;
        this.defineOptions = {
          xAxis: {
            axisTick: {
              show: false,
            },
            type: "category",
            data: [...res.data.data.classVOs.map((item) => item.classNum)],
          },
        };
        this.series1 = [
          {
            type: "bar",
            name: "跟踪生",
            data: [...res.data.data.classVOs.map((item) => item.total)],
            barMaxWidth: 40,
            label: {
              show: true,
              position: "top",
              color: "inherit",
            },
          },
        ];
        this.chartKey = Math.random();
      }
    },
    classNumChange(val) {
      this.checkClassNum = val;
      if (this.type === 2) {
        this.columns = [...this.topCol];
        this.topOrBackward = !this.topOrBackward ? 20 : this.topOrBackward;
        this.getTopOrBackward();
      } else if (this.type === 3) {
        this.columns = [...this.backCol];
        this.topOrBackward = !this.topOrBackward ? 20 : this.topOrBackward;
        this.getTopOrBackward();
      } else if (this.type == 1) {
        this.columns = [...this.waveCol];
        this.getstatcomparelistData();
      }
    },
    async getstatcomparelistData() {
      const res = await getstatcomparelist({
        statId: this.$route.query.id,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.selectOptions = res.data.data;
      if (res.data.data.length) {
        this.wave.upStatId = res.data.data[0].id;
        this.wave.downStatId = res.data.data[0].id;
        this.getWaveData();
      }
    },
    formatterCriticalData(data) {
      let arr = data.map((item, index) => {
        if (index < data.length - 1) {
          return {
            riskList: [...item.riskList],
            potential: [...data[index + 1].potential],
          };
        }
      });
      return arr.filter((item) => item);
    },
    async getCriticalData() {
      const res = await getCritical({
        subjectId: this.subjectId,
        id: this.$route.query.id,
      });
      if (res.data.data.classes.length) {
        let levels = res.data.data.classes[0].levels;
        this.critialColumns = [
          {
            title: "班级",
            width: 85,
            align: "center",
            dataIndex: "classNum",
          },
          ...levels.map((item, index) => ({
            title: item.level,
            align: "center",
            children: [
              {
                title: "潜力生比率",
                align: "center",
                dataIndex: "potentialProportion" + index,
                width: 120,
                customRender: (text, record) =>
                  record.levels[index].potentialProportion + "%",
              },
              {
                title: "风险生比率",
                align: "center",
                width: 120,
                dataIndex: "riskProportion" + index,
                customRender: (text, record) =>
                  record.levels[index].riskProportion + "%",
              },
            ],
          })),
        ];
        this.crititalData = [...res.data.data.classes];
      }
      if (res.data.data.levels.length) {
        this.tableData = res.data.data.levels;
      }
    },
    typeChange() {
      this.series1 = [];
      this.series2 = [];
      this.defineOptions = null;
      this.tableData = [];
      this.checkClassNum = [...this.classNumList];
      if (this.type === 1) {
        this.columns = [...this.waveCol];
        this.getstatcomparelistData();
      } else if (this.type === 2) {
        this.columns = [...this.topCol];
        this.topOrBackward = 20;
        this.getTopOrBackward();
      } else if (this.type === 3) {
        this.columns = [...this.backCol];
        this.topOrBackward = 20;
        this.getTopOrBackward();
      } else if (this.type === 4) {
        this.trackStudent();
      } else if (this.type === 5) {
        this.getCriticalData();
      }
    },
    searchSubmit() {
      if (!/(^[1-9]\d*$)/.test(this.topOrBackward)) {
        this.$message("请输入正整数");
        return;
      } else {
        this.getTopOrBackward();
      }
    },
    validateWave() {
      if (
        !/(^[1-9]\d*$)/.test(this.wave.upTop) ||
        !/(^[1-9]\d*$)/.test(this.wave.upMax)
      ) {
        this.$message("请输入正整数");
        return;
      } else if (!this.wave.upStatId) {
        this.$message({
          showClose: false,
          message: "请选择对比考试",
          type: "error",
        });
      } else {
        this.getWaveData();
      }
    },
    closeLoading(loadingInstance, io) {
      if (loadingInstance && io) {
        this.$nextTick(() => {
          loadingInstance.close();
          io.unobserve(this.$refs.student);
        });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";
.operate {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 18px;
}
.charts {
  margin-bottom: 18px;
  .filter {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 18px;
    span {
      margin: 0 8px;
    }
    div {
      margin-right: 18px;
    }
  }
}
.classNum {
  margin-bottom: 18px;
}
.subjectShit {
  color: #f56c6c;
}
// .critical {
//   display: flex;
//   align-items: flex-start;
// }
.nameList {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  .student {
    color: #2474ed;
    font-size: 14px;
    cursor: pointer;
    margin-right: 8px;
    margin-bottom: 8px;
  }
}
.name-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
