<template>
  <div class="progress-container">
    <div class="title">图.答题统计（正确答案:{{ answer || "暂无" }}）</div>
    <div class="legend">
      <div class="unit">
        <div class="point"></div>
        {{ examType == 1 ? "学校" : "年级" }}
      </div>
    </div>
    <div v-if="data.length" class="content-box">
      <div v-for="(item, index) in data" :key="index" class="progress-item">
        <div class="label">{{ item.item }}</div>
        <div class="com">
          <el-progress
            :stroke-width="22"
            :percentage="item.proportion"
            :color="customColorMethod(item)"
            :format="format"
          ></el-progress>
        </div>
        <div class="num">{{ item.total }}人</div>
        <div class="percentage">占比{{ item.proportion }}%</div>
      </div>
    </div>
    <no-data v-else />
  </div>
</template>

<script>
export default {
  name: "Progress",
  props: {
    data: {
      type: Array,
      default: () => {},
    },
    answer: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      examType: this.$route.query.examType,
    };
  },
  mounted() {},
  methods: {
    customColorMethod(item) {
      if (this.answer) {
        if (this.answer.length > 1) {
          return "#78B5EF";
        } else if (this.answer.length == 1) {
          const rightItem = this.data.find((it) => this.answer == it.item);
          if (this.answer == item.item) {
            return "#6EDBA7";
          } else if (item.proportion > 30) {
            return "#FD8585";
          } else if (rightItem.proportion >= item.proportion) {
            return "#FD8585";
          } else {
            return "#78B5EF";
          }
        }
      } else {
        return "#78B5EF";
      }
    },
    format() {
      return "";
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-container {
  width: 80%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  .title {
    text-align: center;
    font-size: 14px;
  }
  .legend {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 13px 0;
    .unit {
      margin-right: 50px;
      display: flex;
      align-items: center;
      .point {
        background-color: #73a0fa;
        width: 10px;
        height: 10px;
        margin-right: 2px;
        border-radius: 2px;
      }
    }
    .class {
      display: flex;
      align-items: center;
      .point {
        background-color: #ff5233;
        width: 2px;
        height: 12px;
        margin-right: 2px;
      }
    }
  }
  .content-box {
    overflow-y: auto;
    flex: 1;
    margin-bottom: 18px;
    .progress-item {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #8c8c8c;
      box-sizing: border-box;
      height: 32px;

      .label {
        margin-right: 24px;
        width: 50px;
      }
      .com {
        flex: 1;
        margin-right: 24px;
        position: relative;
        .classLine {
          position: absolute;
          background-color: #ff5233;
          width: 2px;
          height: 22px;
          top: 0;
        }
      }
      .num {
        margin-right: 24px;
        width: 75px;
        text-align: right;
      }
      .percentage {
        margin: 0;
        width: 90px;
        text-align: left;
      }
    }
    & :last-child {
      margin: 0;
    }
  }
}
::-webkit-scrollbar-track-piece {
  //滚动条凹槽的颜色，还可以设置边框属性
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  //滚动条的宽度
  width: 9px;
  height: 9px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  //滚动条的设置
  background-color: #dddddd;
  background-clip: padding-box;
  min-height: 28px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #bbb;
}
::v-deep .el-progress-bar__outer {
  background-color: #fafafa;
}
</style>
