<template>
  <div class="diagnosis-questions">
    <div class="tip">
      {{ overview }}
    </div>
    <chart
      v-if="questionSeries.length"
      :key="questionkey"
      style="margin-top: 18px"
      :define-options="questionOptions"
      :series="questionSeries"
      :data-zoom="20"
      :height="'500px'"
    />
    <no-data v-else></no-data>
    <div class="questions">
      选择题目：
      <div class="tab">
        <a-tabs v-model="value" :tab-position="'top'" @tabClick="tabClick">
          <a-tab-pane
            v-for="item in paperQuestionList"
            :key="item.point ? item.point + 'point' : item.questionOrder"
            :tab="
              item.point
                ? item.questionOrder + '-' + item.point
                : item.questionOrder
            "
          >
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
    <div class="box">
      <progressComponent
        v-if="progressData.length"
        :data="progressData"
        :answer="answer"
        style="margin-bottom: 18px"
      />
      <exTable
        v-if="tableData.length"
        :key="tokenKey"
        :columns="columns"
        style="margin-bottom: 18px"
        :data-source="tableData"
        :scroll="{ x: false }"
      />
      <template v-if="showStatClass()">
        <chart
          v-if="singleSeries.length"
          :key="singleKey"
          :define-options="singleOptions"
          :height="'500px'"
          :series="singleSeries"
          :data-zoom="20"
        />
        <div class="checkNum" style="margin-bottom: 18px">
          选择班级：
          <check-component
            :source="classNumsList"
            :placeholder="'选择班级'"
            @change="getCheckClassNum"
          />
        </div>
        <exTable
          v-if="ratioTableData.length"
          :key="tableKey"
          :columns="ratioColumns"
          style="margin-bottom: 18px"
          :data-source="ratioTableData"
          :scroll="{ x: 200 }"
        >
        </exTable>
        <div class="tip">注：标红的得分率表示班级得分率低于我校得分率</div>
      </template>
    </div>
  </div>
</template>

<script>
import chart from "../../../../components/charts.vue";
import exTable from "../../../../components/exTable.vue";
import { getstatclassoption } from "@/core/api/academic/common";
import {
  getresearchreportpaperanalyquestiondiagquestionoverall,
  getpaperquestionlist,
  getresearchreportpaperanalyquestiondiagquestionsingle,
  getresearchreportpaperanalyquestiondiagquestionscorerate,
} from "@/core/api/academic/teachingReport";
import progressComponent from "@/views/academic/teachingReport/components/progress.vue";
import checkComponent from "@/views/academic/teachingReport/components/checkComponent.vue";
import {
  unionGetresearchreportpaperanalyquestiondiagquestionoverall,
  Uniongetresearchreportpaperanalyquestiondiagquestionsingle,
  unionGetresearchreportpaperanalyquestiondiagquestionscorerate,
  paperquestionlist,
} from "@/core/api/newExamAcademic/union/teaching.js";
export default {
  name: "DiagnosisQuestions",
  components: {
    chart,
    progressComponent,
    exTable,
    checkComponent,
  },
  props: {
    subjectId: {
      type: [null, Number],
      default: null,
    },
    topData: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      questionOptions: {},
      questionSeries: [],
      overview: null,
      questionkey: null,
      paperQuestionList: [],
      value: null,
      progressData: [],
      answer: null,
      columnsCols: [
        {
          title: "题号",
          dataIndex: "order",
          align: "center",
        },
        {
          title: "满分分值",
          dataIndex: "score",
          align: "center",
        },
        {
          title: "平均分",
          dataIndex: "averageScore",
          align: "center",
        },
        {
          title: "校得分率",
          dataIndex: "scoreRate",
          align: "center",
        },

        {
          title: "满分人数",
          dataIndex: "countRight",
          align: "center",
        },
        {
          title: "零分人数",
          dataIndex: "countZero",
          align: "center",
        },
        {
          title: "答错人数",
          dataIndex: "countWrong",
          align: "center",
        },
        {
          title: "知识点",
          dataIndex: "knowledgeNames",
          align: "center",
          width: 350,
        },
      ],
      columns: [],
      tableData: [],
      singleOptions: {},
      singleSeries: [],
      singleKey: null,
      ratioTableData: [],
      classNumsList: [],
      classNumChecked: [],
      ratioColumns: [],
      ratioColumnsCols: [
        {
          dataIndex: "name",
          title: "题号",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "schoolScoreRate",
          title: "校得分率",
          align: "center",
          width: 120,
          customRender: (text) => text || "",
        },
        {
          title: "整体得分率",
          dataIndex: "unionScoreRate",
          align: "center",
        },
      ],
      classTableLabel: [],
      tableKey: null,
      ratioScroll: false,
      tokenKey: Math.random(),
    };
  },
  created() {},
  mounted() {
    this.getQuestionData();
    this.getClassList();
    this.getpaperquestionlistData();
  },
  methods: {
    showStatClass() {
      if (this.$route.query.examType == 1) {
        return this.topData.statClass == 1;
      }
      return true;
    },
    async getQuestionData() {
      // 获取各小题数据
      this.questionSeries.length = 0;
      let res;

      if (this.$route.query.schoolId) {
        res = await unionGetresearchreportpaperanalyquestiondiagquestionoverall(
          {
            id: this.$route.query.id,
            subjectId: this.subjectId,
            viewSchoolId: this.$route.query.schoolId,
          }
        );
      } else {
        res = await getresearchreportpaperanalyquestiondiagquestionoverall({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }
      this.overview = res.data.data.overview;
      let data =
        res.data.data
          .researchReportPaperAnalyQuestionDiagQuestionOverallQuestionVOs;
      this.questionOptions = {
        tooltip: {
          trigger: "axis",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          valueFormatter: function (val) {
            return Math.abs(val);
          },
        },
        legend: { itemGap: 30 },
        grid: {
          bottom: data.length > 25 ? "20%" : "5%",
          containLabel: data.length > 2,
        },
        dataZoom: [
          //滚动条
          {
            show: data.length > 25,
            type: "slider",
            realtime: true,
            startValue: 0, // 重点
            // 重点-dataX.length表示x轴数据长度
            endValue: data.length - (data.length - 25),
            xAxisIndex: [0],
            bottom: "5%",
            left: "center",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: data.map((item) => item.order),
            axisTick: {
              show: false,
            },
            axisLabel: {
              show: true,

              textStyle: {
                color: "rgba(0, 0, 0, 0.45)",
              },
              formatter: (val) => {
                let arr = val.split("");
                let index = 0;
                let newArray = [];
                while (index < arr.length) {
                  let data = arr.slice(index, (index += 7));
                  newArray.push(data.join(""));
                }
                let str = newArray.reduce((str, item) => str + "\n" + item, "");
                return str;
              },
            },
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        color: ["#619AF1", "#FF9776", "#6EDBA7", "#FFD766"],
      };
      this.questionSeries = [
        {
          name: this.$route.query.examType == 1 ? "校得分率" : "得分率", // 不同条柱
          type: "bar",
          label: {
            position: "top",
            show: true,
            color: "inherit",
            fontSize: 9,
          },
          barMaxWidth: 40,
          data: data.map((item) => {
            if (item.isLow === 1) {
              return {
                value: item.scoreRate || "0",
                itemStyle: { color: "#F56C6B" },
              };
            }
            return item.scoreRate || "0";
          }),
        },
      ];
      if (
        this.$route.query.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        this.questionSeries.push({
          name: "整体得分率",
          type: "line",
          symbol: "none",
          smooth: true,
          data: data.map((item) => item.unionScoreRate),
        });
      }
      this.questionkey = Math.random();
    },
    async getpaperquestionlistData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await paperquestionlist({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getpaperquestionlist({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      }

      this.paperQuestionList = res.data.data;
      if (res.data.data.length) {
        this.value = res.data.data[0].questionOrder;
        this.getQuestionSingleData();
      }
    },
    tabClick(val) {
      this.value = val;
      this.getQuestionSingleData();
    },
    async getQuestionSingleData() {
      // 获取但道题的得分详情
      this.singleOptions = {};
      this.singleSeries = [];
      if (this.value) {
        let field = "questionOrder";
        let value = this.value;
        if (this.value.indexOf("point") > -1) {
          field = "point";
          let stringArr = this.value.split("point");
          value = stringArr[0];
        }
        let question = this.paperQuestionList.find(
          (item) => item[field] === value
        );
        let params = {
          id: this.$route.query.id,
          subjectId: this.subjectId,
          order: question.questionOrder,
          point: question.point,
        };
        let res;
        if (this.$route.query.schoolId) {
          params.viewSchoolId = this.$route.query.schoolId;
          res =
            await Uniongetresearchreportpaperanalyquestiondiagquestionsingle(
              params
            );
        } else {
          res = await getresearchreportpaperanalyquestiondiagquestionsingle(
            params
          );
        }

        this.progressData =
          res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleAnswerVOs;
        this.answer = res.data.data.rightAnswer;
        let cols = [...this.columnsCols];
        if (
          this.$route.query.examType == 1 &&
          this.topData.isSchoolDisplayReportUnionAverageScore == 1
        ) {
          cols.splice(4, 0, {
            dataIndex: "unionScoreRate",
            align: "center",
            title: "整体得分率",
          });
        }
        this.columns = [...cols];
        this.tableData = [
          ...res.data.data
            .researchReportPaperAnalyQuestionDiagQuestionSingleStatVOS,
        ];
        this.tokenKey = Math.random();
        if (
          res.data.data
            .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.length
        ) {
          this.singleOptions = {
            tooltip: {
              trigger: "axis",
              backgroundColor: "rgba(0,0,0,0.7)",
              borderColor: "rgba(0,0,0,0.7)",
              textStyle: {
                color: "#fff",
              },
              axisPointer: {
                type: "shadow",
                shadowStyle: {
                  color: "rgba(0, 0, 0, 0.1)",
                },
              },

              valueFormatter: function (val) {
                return Math.abs(val);
              },
            },
            legend: { itemGap: 30 },
            grid: {
              bottom:
                res.data.data
                  .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs >
                25
                  ? "20%"
                  : "5%",
              containLabel: true,
            },
            dataZoom: [
              //滚动条
              {
                show:
                  res.data.data
                    .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs >
                  25,
                type: "slider",
                realtime: true,
                startValue: 0, // 重点
                // 重点-dataX.length表示x轴数据长度
                endValue:
                  res.data.data
                    .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs
                    .length -
                  (res.data.data
                    .researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs
                    .length -
                    25),
                xAxisIndex: [0],
                bottom: "5%",
                left: "center",
              },
            ],
            xAxis: [
              {
                type: "category",
                data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                  (item) => item.classNum
                ),
                axisTick: {
                  show: false,
                },
                axisLabel: {
                  show: true,
                  textStyle: {
                    color: "rgba(0, 0, 0, 0.45)",
                  },
                  rotate: "45",
                },
              },
            ],
            yAxis: [
              {
                type: "value",
              },
            ],
            color: ["#619AF1", "#FF9776", "#6EDBA7", "#FFD766"],
          };
          this.singleSeries = [
            {
              name: "班级得分率", // 不同条柱
              type: "bar",
              label: {
                position: "top",
                show: true,
                color: "inherit",
                fontSize: 9,
              },
              barMaxWidth: 40,
              data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                (item) => {
                  if (item.isLow == 1) {
                    return {
                      value: item.classScoreRate || "0",
                      itemStyle: { color: "#F56C6B" },
                    };
                  }
                  return item.classScoreRate || "0";
                }
              ),
            },
            {
              name: "学校得分率",
              type: "line",
              color: "#FFA486",
              data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                () => res.data.data.schoolScoreRate || 0
              ),
            },
          ];
          if (
            this.$route.query.examType == 1 &&
            this.topData.isSchoolDisplayReportUnionAverageScore == 1
          ) {
            this.singleSeries.push({
              name: "整体得分率",
              type: "line",
              symbol: "none",
              smooth: true,
              data: res.data.data.researchReportPaperAnalyQuestionDiagQuestionSingleClassVOs.map(
                () => res.data.data.unionScoreRate
              ),
            });
          }
          this.singleKey = Math.random();
        }
      }
    },
    async getClassNumData() {
      let cols = [
        {
          dataIndex: "name",
          title: "题号",
          align: "center",
          width: 150,
        },
        {
          dataIndex: "schoolScoreRate",
          title: "校得分率",
          align: "center",
          width: 120,
          customRender: (text) => text || "",
        },
        {
          title: "整体得分率",
          dataIndex: "unionScoreRate",
          width: 120,
          align: "center",
        },
      ];
      let colNum = 2;
      if (
        this.$route.query.examType == 1 &&
        this.topData.isSchoolDisplayReportUnionAverageScore == 1
      ) {
        colNum = 3;
        cols.splice(1, 0, {
          dataIndex: "unionScoreRate",
          align: "center",
          title: "整体得分率",
        });
      }
      let res;
      if (this.$route.query.schoolId) {
        res =
          await unionGetresearchreportpaperanalyquestiondiagquestionscorerate({
            subjectId: this.subjectId,
            classNums: this.classNumChecked.toString(),
            id: this.$route.query.id,
            viewSchoolId: this.$route.query.schoolId,
          });
      } else {
        res = await getresearchreportpaperanalyquestiondiagquestionscorerate({
          subjectId: this.subjectId,
          classNums: this.classNumChecked.toString(),
          id: this.$route.query.id,
        });
      }

      this.ratioColumns = [...cols];
      let data =
        res.data.data
          .researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionVOs;
      this.ratioTableData = data;

      if (data.length) {
        this.classTableLabel =
          data[0].researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs;
      }
      if (this.classTableLabel.length) {
        // this.ratioScroll = "max-content";
        this.ratioColumns[colNum] = {
          title: "班级得分率",
          children: [
            ...this.classTableLabel.map((item, index) => {
              return {
                dataIndex: "classScoreRate" + index,
                title: item.classNum,
                align: "center",
                width: 100,
                customRender: (text, record) => {
                  let data =
                    record
                      .researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs[
                      index
                    ].classScoreRate;
                  return <span>{data}</span>;
                },
                customCell: (record) => {
                  let data =
                    record
                      .researchReportPaperAnalyQuestionDiagQuestionScoreRateQuestionClassVOs[
                      index
                    ].classScoreRate;
                  if (record.schoolScoreRate >= data) {
                    return {
                      style: {
                        color: "#fd8585",
                      },
                    };
                  }
                },
              };
            }),
          ],
          align: "center",
        };
        this.$forceUpdate();
        this.tableKey = Math.random();
      } else {
        this.ratioScroll = false;
        this.ratioColumns.pop();
        this.ratioColumns.forEach((item) => {
          this.$set(item, "width", "auto");
        });
        this.tableKey = Math.random();
      }
    },
    async getClassList() {
      const res = await getstatclassoption({
        id: this.$route.query.id,
        subjectId: this.subjectId,
        requestPage: 2,
        viewSchoolId: this.$route.query.schoolId,
      });
      this.classNumsList = res.data.data;
      this.classNumChecked = res.data.data.map((item) => item.name);
      this.getClassNumData();
    },
    getCheckClassNum(val) {
      this.classNumChecked = val.map((item) => item.name);
      this.getClassNumData();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../../new-exam.module.scss";

.diagnosis-questions {
  .questions {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .label {
      white-space: nowrap;
      width: 120px;
    }
    .tab {
      width: calc(100% - 120px);
    }
  }
}
.box {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 18px;
  padding: 18px;
  border: 1px solid #e9e9e9;
  border-radius: 8px;
}
.tab {
  ::v-deep .ant-tabs-bar {
    margin: 0 0 0 0;
  }
}
</style>
