<template>
  <div class="container">
    <exportBtn
      :request-func="requestFunc"
      :params="{
        id: $route.query.id,
        type: 1,
        subjectId: subjectId,
      }"
    >
      <div class="title">试卷分析</div>
    </exportBtn>
    <div class="data">
      <div class="wrapper">
        <div class="data-item">
          <div class="num">
            {{ (paperQualityData.scoreRate || 0 / 1).toFixed(4) || "" }}
          </div>
          <div class="data-title">难度</div>
        </div>
        <div class="data-item">
          <div class="num">
            {{ (paperQualityData.reliability || 0 / 1).toFixed(4) || 0 }}
          </div>
          <div class="data-title">信度</div>
        </div>
        <div class="data-item">
          <div class="num">
            {{ (paperQualityData.discrimination || 0 / 1).toFixed(4) || "" }}
          </div>
          <div class="data-title">区分度</div>
        </div>
      </div>
      <div v-if="$route.query.examType == 1" class="tips">
        注：本模块中难度、 区分度等试卷质量，试题质量指标均是区域整体的统计结果.
      </div>
    </div>
    <div class="sub-title">命题质量</div>
    <div class="pie-box">
      <div class="pie">
        <chart
          :key="difficultKey"
          :define-options="difficultyOptions"
          :show-axis="false"
          :series="difficultySeries"
        />
      </div>
      <div class="table">
        <div class="table-title">难度分布</div>
        <a-table
          :columns="difficultCols"
          :data-source="difficultyTable"
          bordered
          :pagination="false"
        >
          <div slot="objectiveOrder" slot-scope="text, record">
            <a-tooltip placement="top" :title="record.objectiveOrder">
              <div class="overflowStyle">{{ record["objectiveOrder"] }}</div>
            </a-tooltip>
          </div>
          <div slot="subjectiveOrder" slot-scope="text, record">
            <a-tooltip placement="top" :title="record.subjectiveOrder">
              <div class="overflowStyle">{{ record["subjectiveOrder"] }}</div>
            </a-tooltip>
          </div>
        </a-table>
      </div>
    </div>
    <div class="pie-box">
      <div class="pie">
        <chart
          :key="distinguishKey"
          :show-axis="false"
          :define-options="distinguishOptions"
          :series="distinguishSeries"
        />
      </div>
      <div class="table">
        <div class="table-title">区分度分布</div>
        <a-table
          :columns="difficultCols"
          :data-source="distinguishTable"
          bordered
          :pagination="false"
        >
          <div slot="objectiveOrder" slot-scope="text, record">
            <a-tooltip placement="top" :title="record.objectiveOrder">
              <div class="overflowStyle">{{ record["objectiveOrder"] }}</div>
            </a-tooltip>
          </div>
          <div slot="subjectiveOrder" slot-scope="text, record">
            <a-tooltip placement="top" :title="record.subjectiveOrder">
              <div class="overflowStyle">{{ record["subjectiveOrder"] }}</div>
            </a-tooltip>
          </div>
        </a-table>
      </div>
    </div>
    <div class="sub-title">试卷命题质量</div>
    <template v-if="subjectId !== null">
      <quality :key="'quality' + subjectId" :top-data="topData" />
      <diagnosis
        :key="'diagnosis' + subjectId"
        :top-data="topData"
        :subject-id="subjectId"
      />
    </template>
  </div>
</template>

<script>
import { getresearchreportpaperanalypaperquality } from "@/core/api/academic/teachingReport";
import chart from "../../../components/charts.vue";
import quality from "./components/quality.vue";
import { difficultCols } from "./columns";
import { exportTeachingReportNew } from "@/core/api/academic/teachingReport";
import { unionGetresearchreportpaperanalypaperquality } from "@/core/api/newExamAcademic/union/teaching.js";
import exportBtn from "@/views/academic/components/exportBtn.vue";
import diagnosis from "./components/diagnosis.vue";
export default {
  name: "",
  components: {
    chart,
    quality,
    diagnosis,
    exportBtn,
  },
  props: {
    subjectId: {
      type: [Number, null],
      default: null,
    },
    topData: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      requestFunc: exportTeachingReportNew,
      paperQualityData: {},
      difficultyOptions: {},
      difficultySeries: {},
      difficultKey: null,
      difficultyTable: [],
      difficultCols: [...difficultCols],
      distinguishCols: [...difficultCols],
      distinguishTable: [],
      distinguishKey: null,
      distinguishOptions: {},
      distinguishSeries: [],
    };
  },
  mounted() {
    this.getresearchreportpaperanalypaperqualityData();
  },
  methods: {
    async getresearchreportpaperanalypaperqualityData() {
      let res;
      if (this.$route.query.schoolId) {
        res = await unionGetresearchreportpaperanalypaperquality({
          id: this.$route.query.id,
          subjectId: this.subjectId,
          viewSchoolId: this.$route.query.schoolId,
        });
      } else {
        res = await getresearchreportpaperanalypaperquality({
          id: this.$route.query.id,
          subjectId: this.subjectId,
        });
      }

      this.paperQualityData = res.data.data;
      this.difficultyTable =
        res.data.data.researchReportPaperAnalyPaperQualityDifficultyVOs;
      this.distinguishTable =
        res.data.data.researchReportPaperAnalyPaperQualityDiscriminationVOs;
      this.setDifficulty();
      this.setDistinguish();
    },
    setDifficulty() {
      this.difficultyOptions = {
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          axisPointer: {
            type: "shadow",
            shadowStyle: {
              color: "rgba(0, 0, 0, 0.1)",
            },
          },

          formatter: function ({ data, name }) {
            const { score, value } = data;
            let str =
              "难度：" +
              name +
              "<br />" +
              "占比：" +
              score +
              "(" +
              value +
              "%)";
            return str;
          },
        },
        xAxis: { show: false },
        yAxis: { show: false },
      };
      this.difficultySeries = [
        {
          name: "试卷难度分布",
          type: "pie",
          radius: "70%",
          data: this.difficultyTable.map((item) => {
            return {
              value: item.proportion,
              name: item.name,
              score: item.score,
            };
          }),
          label: {
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
          },
          color: ["#6EDBA7", "#FF9776", "#7AB5EF"],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: "1",
          },
        },
      ];
      this.difficultKey = Math.random();
    },
    setDistinguish() {
      this.distinguishOptions = {
        xAxis: { show: false },
        yAxis: { show: false },
        tooltip: {
          trigger: "item",
          backgroundColor: "rgba(0,0,0,0.7)",
          borderColor: "rgba(0,0,0,0.7)",
          textStyle: {
            color: "#fff",
          },
          formatter: function ({ data, name }) {
            const { score, value } = data;
            let str =
              "区分度：" +
              name +
              "<br />" +
              "题量占比：" +
              score +
              "(" +
              value +
              "%)";
            return str;
          },
        },
      };
      this.distinguishSeries = [
        {
          name: "试卷区分度分布",
          type: "pie",
          radius: "70%",
          data: this.distinguishTable.map((item) => {
            return {
              value: item.proportion,
              name: item.name,
              score: item.score,
            };
          }),
          label: {
            textStyle: {
              color: "inherit",
              fontSize: 9,
            },
          },
          color: ["#6EDBA7", "#FFD766", "#A997FB", "#FD8585"],
          itemStyle: {
            borderColor: "#fff",
            borderWidth: "1",
          },
        },
      ];
      this.distinguishKey = Math.random();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "../../../new-exam.module.scss";

.data {
  background-color: #f4f9fe;
  padding: 20px 0;
  box-sizing: border-box;
  margin-bottom: 18px;
  .wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: center;

    .data-item {
      flex: 1;
      padding: 12px 0;
      border-radius: 8px;

      .num {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 6px;
        width: 100%;
        color: #3f87f4;
      }

      .data-title {
        color: #3e4551;
        font-size: 12px;
      }
    }

    .data-item + .data-item {
      margin-left: 24px;
    }
  }
  .tips {
    font-size: 14px;
    text-align: center;
    margin-top: 18px;
    color: #6f6f70;
  }
}

.pie-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  border: 1px solid #ebecee;
  box-sizing: border-box;
  padding: 18px;

  .pie {
    width: 35%;

    .chart {
      margin-bottom: 0;
    }
  }

  .table {
    width: 65%;

    .table-title {
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
}

.ant-table {
  table-layout: fixed;
}

.ant-table-tbody > tr > td {
  border-bottom: 0;
  /*text-align: center !important;*/
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  word-break: break-all;
}

.overflowStyle {
  max-width: 100px !important;
  max-height: 70px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-wrap: break-word;
  word-break: break-all;
}
</style>
