export const avgCol = [
  {
    title: "排名",
    dataIndex: "rank",
    align: "center",
    width: 75,
  },
  {
    title: "班级",
    dataIndex: "classNum",
    align: "center",
    width: 100,
  },
  {
    title: "任课教师",
    dataIndex: "teacherName",
    align: "center",
    width: 100,
  },
  {
    title: "统计人数",
    dataIndex: "statTotal",
    align: "center",
    width: 100,
  },
  {
    title: "平均分",
    dataIndex: "averageScoreOri",
    align: "center",
    width: 85,
  },
  {
    title: "中位数",
    dataIndex: "medianOri",
    align: "center",
    width: 85,
  },
  {
    title: "赋分均分",
    dataIndex: "averageScore",
    align: "center",
    width: 100,
  },
  {
    title: "最高分",
    dataIndex: "highestScoreOri",
    align: "center",
    width: 85,
  },
  {
    title: "最低分",
    dataIndex: "lowestScoreOri",
    align: "center",
    width: 85,
  },
  {
    title: "超均率",
    dataIndex: "overAverageRateOri",
    align: "center",
    width: 85,
  },
  {
    title: "排名前27%均分",
    dataIndex: "top27AverageScoreOri",
    align: "center",
    width: 150,
  },
  {
    title: "排名后27%均分",
    dataIndex: "after27AverageScoreOri",
    align: "center",
    width: 150,
  },
  {
    title: "区分度",
    dataIndex: "discriminationOri",
    align: "center",
    width: 85,
    customRender: (text, record) => record.discriminationOri.toFixed(2),
  },
];
export const quartileCol = [
  {
    title: "教学班",
    dataIndex: "classNum",
    align: "center",
    width: 100,
  },
  {
    title: "授课教师",
    dataIndex: "teacherName",
    align: "center",
    width: 100,
  },
  {
    title: "统计人数",
    dataIndex: "statTotal",
    align: "center",
    width: 100,
  },
  {
    title: "中位数",
    dataIndex: "medianOri",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "upperWhisker",
    title: "上限",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "highestScoreOri",
    title: "最高分",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "lowerWhisker",
    title: "下限",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "lowestScoreOri",
    title: "最低分",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "interQuartileRange",
    title: "四分位间距",
    align: "center",
    width: 120,
  },
  {
    dataIndex: "rangeScore",
    title: "全距/极差",
    align: "center",
    width: 120,
  },
];
export const ratioCol = [
  {
    dataIndex: "classNum",
    title: "班级",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "teacherName",
    title: "授课教师",
    align: "center",
    width: 100,
  },
  {
    dataIndex: "statTotal",
    title: "统计人数",
    align: "center",
    width: 100,
  },
];
export const topSectionCol = [
  {
    dataIndex: "classNum",
    title: "班级",
    align: "center",
    width: 85,
  },
  {
    dataIndex: "teacherName",
    title: "授课教师",
    align: "center",
    width: 100,
  },
  {
    dataIndex: "statTotal",
    title: "统计人数",
    align: "center",
    width: 100,
  },
];
export const uniCols = {
  avg: [
    {
      dataIndex: "rank",
      title: "校排名",
      align: "center",
    },
    {
      dataIndex: "classNum",
      title: "班级",
      align: "center",
    },
    {
      dataIndex: "teacherName",
      title: "授课教师",
      align: "center",
    },
    {
      dataIndex: "statTotal",
      title: "统计人数",
      align: "center",
    },
    {
      dataIndex: "averageScoreOri",
      scopedSlots: { title: "averageScoreOri" },
      align: "center",
    },
    {
      dataIndex: "averageScore",
      title: "赋分均分",
      align: "center",
    },
    {
      dataIndex: "medianOri",
      scopedSlots: { title: "medianOri" },
      align: "center",
    },

    {
      dataIndex: "highestScoreOri",
      scopedSlots: { title: "highestScoreOri" },
      align: "center",
    },
    {
      dataIndex: "lowestScoreOri",
      scopedSlots: { title: "lowestScoreOri" },
      align: "center",
    },
    {
      dataIndex: "overAverageRateUnionOri",
      scopedSlots: { title: "unionOverAverageRateOri" },
      align: "center",
    },

    {
      dataIndex: "top27AverageScoreOri",
      scopedSlots: { title: "top27AverageScoreOri" },
      align: "center",
    },
    {
      dataIndex: "after27AverageScoreOri",
      scopedSlots: { title: "after27AverageScoreOri" },
      align: "center",
    },
    {
      dataIndex: "discriminationOri",
      title: "区分度",
      align: "center",
      customRender: (test, record) =>
        record.discriminationOri ? record.discriminationOri.toFixed(2) : "",
    },
  ],
  ratios: [
    {
      dataIndex: "classNum",
      title: "班级",
      align: "center",
    },
    {
      dataIndex: "teacherName",
      title: "任课教师",
      align: "center",
    },
    {
      dataIndex: "statTotal",
      title: "统计人数",
      align: "center",
    },
  ],
};
